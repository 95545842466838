import DataProvider from './DataProvider';
import { calendariosProvider } from './index';
import { formatISODate } from '../utils';

export const empty = {
    year: '',
    dates: '',
};

class CalendariosProvider extends DataProvider {
    constructor() {
        super('calendarios', empty);
    }

    resetNoLaborables = async (currentYear) => {
        const day = new Date(currentYear, 0, 1);
        if (day.getDay() < 6) day.setDate(day.getDate() + 6 - day.getDay());

        const dates = [];
        while (day.getFullYear() <= currentYear) {
            dates.push(new Date(day));
            day.setDate(day.getDate() + 1);
            dates.push(new Date(day));
            day.setDate(day.getDate() + 6);
        }

        return calendariosProvider.getAll(`${currentYear}`, {
            method: 'post',
            body: JSON.stringify({ dates: dates.map(formatISODate) }),
        });
    };

    getNoLaborables = async (currentYear) => this.getAll(`no_laborables/${currentYear}`);
}

export const dataProvider = new CalendariosProvider();
