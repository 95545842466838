import PropTypes from 'prop-types';
import startOfWeek from 'date-fns/startOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import esES from 'date-fns/locale/es';
import endOfWeek from 'date-fns/endOfWeek';
import endOfMonth from 'date-fns/endOfMonth';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import { CustomFilter } from '../../../common/CustomFilter';

export function ParteTrabajoFechaFilter({ onFilterChange }) {
    const options = [
        {
            label: 'Todos',
            onClick: () => onFilterChange(null),
        },
        {
            label: 'Esta semana',
            onClick: () => {
                onFilterChange({
                    start: startOfWeek(new Date(), { locale: esES }),
                    end: endOfWeek(new Date(), { locale: esES }),
                });
            },
        },
        {
            label: 'Semana pasada',
            onClick: () => {
                onFilterChange({
                    start: startOfWeek(subDays(new Date(), 7), { locale: esES }),
                    end: endOfWeek(subDays(new Date(), 7), { locale: esES }),
                });
            },
        },
        {
            label: 'Este mes',
            onClick: () => {
                onFilterChange({
                    start: startOfMonth(new Date(), { locale: esES }),
                    end: endOfMonth(new Date(), { locale: esES }),
                });
            },
        },
        {
            label: 'Mes anterior',
            onClick: () => {
                onFilterChange({
                    start: startOfMonth(subMonths(new Date(), 7), { locale: esES }),
                    end: endOfMonth(subMonths(new Date(), 7), { locale: esES }),
                });
            },
        },
    ];

    return <CustomFilter label='Fecha' options={options} />;
}

ParteTrabajoFechaFilter.propTypes = {
    onFilterChange: PropTypes.any,
};
