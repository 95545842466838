import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import * as Yup from 'yup';
import { companiesProvider } from '../../../../api';
import API_ROOT from '../../../../api/api-config';
import useAuthState from '../../../../AuthState';
import AuthService from '../../../auth/AuthService';
import { useEditFormData } from '../../../common/forms/EditForm/EditFormContext';
import MasterDetailsView from '../../../common/forms/MasterDetailsView/MasterDetailsView';
import EditPreferenciasDelsol from '../PreferenciasDelsol/EditPreferenciasDelsol';
import EditPreferenciasTelematel from '../PreferenciasTelematel/EditPreferenciasTelematel';
import AjustesAlbaranes from './AjustesAlbaranes';
import AjustesBackups from './AjustesBackups';
import AjustesDatosAcceso from './AjustesDatosAcceso';
import AjustesDetails from './AjustesDetails';
import AjustesEquipos from './AjustesEquipos';
import AjustesItem from './AjustesItem';
import AjustesNoLaborables from './AjustesNoLaborables';
import AjustesNotificaciones from './AjustesNotificaciones';
import AjustesPreciosGastos from './AjustesPreciosGastos';
import AjustesServicios from './AjustesServicios';
import AjustesCategoriasPrecios from './CategoriasPrecios/AjustesCategoriasPrecios';
import AjustesCategoriasTarea from './CategoriasTarea/AjustesCategoriasTarea';
import AjustesDelegaciones from './Delegaciones/AjustesDelegaciones';
import AjustesHorarios from './Horarios/AjustesHorarios';

const PreferenciasSchema = Yup.object().shape({
    usar_referencias_tareas: Yup.bool().required('Requerido'),
    tipo_envio_email_copia_albaran: Yup.string(),
    email_copia_albaran_list: Yup.string().when(['tipo_envio_email_copia_albaran'], {
        is: (value) => value !== 'NUNCA',
        then: Yup.string().required('Requerido'),
        otherwise: Yup.string().notRequired(),
    }),
    enviar_email_salir_servicio: Yup.bool(),
    email_salir_servicio_list: Yup.string().when(['enviar_email_salir_servicio'], {
        is: true,
        then: Yup.string().required('Requerido'),
        otherwise: Yup.string().notRequired(),
    }),
    enviar_email_cerrar_servicio: Yup.bool(),
    email_cerrar_servicio_list: Yup.string().when(['enviar_email_cerrar_servicio'], {
        is: true,
        then: Yup.string().required('Requerido'),
        otherwise: Yup.string().notRequired(),
    }),
    enviar_email_solicitud_vacaciones_ausencias: Yup.bool(),
    email_solicitud_vacaciones_ausencias_list: Yup.string().when(['enviar_email_solicitud_vacaciones_ausencias'], {
        is: true,
        then: Yup.string().required('Requerido'),
        otherwise: Yup.string().notRequired(),
    }),
    // enviar_email_solicitud_material: Yup.bool(),
    // email_solicitud_material_list: Yup.string().when(['enviar_email_solicitud_material'], {
    //     is: true,
    //     then: Yup.string().required('Requerido'),
    //     otherwise: Yup.string().notRequired(),
    // }),
    // preferencias_telematel: Yup.object()
    //     .notRequired()
    //     .default(undefined)
    //     .shape({
    //         gastos: Yup.object().shape({
    //             tipo_linea: Yup.string().required('Requerido'),
    //         }),
    //         tipo_linea_operarios: Yup.string().required('Requerido'),
    //     }),
});

const useStyles = makeStyles(
    (theme) => ({
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
            '& .MuiIconButton-root': {
                marginLeft: 'auto',
                marginRight: theme.spacing(1),
            },
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'auto',
        },
        saveButton: {
            alignSelf: 'flex-end',
            // marginTop: -theme.spacing(6.5),
            // marginBottom: theme.spacing(2.5),
        },
        content: {
            padding: theme.spacing(2),
            '&>.MuiGrid-item:not(:last-of-type)': {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
        },
        rows: {
            display: 'flex',
            flexDirection: 'column',
        },
        row: {
            display: 'flex',
            padding: theme.spacing(0, 2),
            gap: `${theme.spacing(2)}px`,
            alignItems: 'center',
            '&>div:first-of-type': {
                width: '50%',
            },
            '&:not(:last-of-type)': {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
        },
        sectionItemContainer: {
            maxWidth: 300,
            gap: `${theme.spacing(1)}px !important`,
        },
    }),
    { name: 'AjustesTab' },
);

export default function AjustesTab() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const { updateLoginInfo } = useAuthState();

    const { data, fetchData } = useEditFormData('preferencias');
    const { data: usuario } = useEditFormData('user');

    const {
        userInfo: {
            preferencias: {
                usar_telematel: usarTelematel,
                usar_delsol: usarDelsol,
                usar_delegaciones: usarDelegaciones,
                usar_backups: usarBackups,
            } = {},
        },
    } = useAuthState();

    return (
        <Formik
            initialValues={{
                ...data,
                logo_file: null,
                borrar_logo: false,
                preferencias_delsol: usarDelsol && data.preferencias_delsol ? data.preferencias_delsol : null,
                preferencias_telematel:
                    usarTelematel && data.preferencias_telematel
                        ? {
                              ...data.preferencias_telematel,
                              operarios: Object.entries(data.preferencias_telematel.operarios).map(([id, rest]) => ({
                                  id,
                                  ...rest,
                              })),
                          }
                        : null,
            }}
            enableReinitialize
            validationSchema={PreferenciasSchema}
            onSubmit={({ preferencias_telematel: telematel, preferencias_delsol: delsol, ...values }, actions) => {
                const formData = new FormData();
                Object.entries(values).forEach(([key, value]) => formData.append(key, value !== null ? value : ''));

                if (telematel) {
                    const preferenciasTelematel = {
                        ...telematel,
                        operarios: Object.fromEntries(telematel.operarios.map(({ id, ...rest }) => [id, rest])),
                    };

                    formData.append('preferencias_telematel', JSON.stringify(preferenciasTelematel));
                }

                if (delsol) {
                    formData.append('preferencias_delsol', JSON.stringify(delsol));
                }

                companiesProvider
                    .getAll('profile/preferencias', {
                        method: 'post',
                        body: formData,
                    })
                    .then((result) => {
                        const authService = new AuthService(API_ROOT);
                        authService.updateUserInfo().then(updateLoginInfo);

                        actions.setSubmitting(false);
                        fetchData();
                        snackbar.showMessage('Se han actualizado las preferencias con éxito');
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.status === 400) {
                            for (const [field, errors] of Object.entries(err.message)) {
                                actions.setFieldError(field, errors.join('\n'));
                            }
                        }
                        actions.setSubmitting(false);
                    });
            }}
        >
            {({ dirty, values, setFieldValue, isSubmitting }) => {
                const ajustesSections = [
                    {
                        id: 'servicios',
                        label: 'Servicios',
                        content: <AjustesServicios classes={classes} />,
                    },
                    {
                        id: 'albaranes-servicio',
                        label: 'Albaranes de servicio',
                        content: (
                            <AjustesAlbaranes
                                classes={classes}
                                values={values}
                                setFile={(file) => setFieldValue('logo_file', file)}
                            />
                        ),
                    },
                    {
                        id: 'notificaciones',
                        label: 'Notificaciones',
                        content: <AjustesNotificaciones classes={classes} values={values} />,
                    },
                    {
                        id: 'precios-gastos',
                        label: 'Precios de gastos',
                        content: <AjustesPreciosGastos classes={classes} />,
                    },
                    // {
                    //     id: 'rendimientos',
                    //     label: 'Rendimientos',
                    //     content: <AjustesRendimientos classes={classes} />,
                    // },
                    {
                        id: 'equipos',
                        label: 'Equipos',
                        content: <AjustesEquipos classes={classes} />,
                    },
                ];

                ajustesSections.push(
                    {
                        id: 'no-laborables',
                        label: 'Dias no laborables',
                        content: <AjustesNoLaborables classes={classes} />,
                    },
                    {
                        id: 'horarios',
                        label: 'Horarios',
                        content: <AjustesHorarios classes={classes} />,
                    },
                    {
                        id: 'datos-acceso',
                        label: 'Datos de acceso',
                        content: <AjustesDatosAcceso classes={classes} usuario={usuario} onSave={fetchData} />,
                    },
                    {
                        id: 'categorias-operarios',
                        label: 'Categorías y precios de operario',
                        content: <AjustesCategoriasPrecios />,
                    },
                    // {
                    //     id: 'categorias-operario',
                    //     label: 'Categorías de operario',
                    //     content: <AjustesCategoriasOperario classes={classes} />,
                    // },
                    // {
                    //     id: 'tipos-hora',
                    //     label: 'Tipos de hora',
                    //     content: <AjustesTiposHora classes={classes} />,
                    // },
                    {
                        id: 'categorias-servicio',
                        label: 'Categorías de servicio',
                        content: <AjustesCategoriasTarea classes={classes} />,
                    },
                );

                if (usarBackups) {
                    ajustesSections.push({
                        id: 'exportar-datos',
                        label: 'Exportación de datos',
                        content: <AjustesBackups classes={classes} />,
                    });
                }

                if (usarDelegaciones) {
                    ajustesSections.push({
                        id: 'delegaciones',
                        label: 'Delegaciones',
                        content: <AjustesDelegaciones classes={classes} />,
                    });
                }

                if (usarTelematel) {
                    ajustesSections.push({
                        id: 'telematel',
                        label: 'Integración con Telematel',
                        content: <EditPreferenciasTelematel classes={classes} />,
                    });
                }

                if (usarDelsol) {
                    ajustesSections.push({
                        id: 'delsol',
                        label: 'Integración con DELSOL',
                        content: <EditPreferenciasDelsol classes={classes} />,
                    });
                }

                return (
                    <Form className={classes.form}>
                        <MasterDetailsView
                            masterComponent={AjustesItem}
                            detailsComponent={AjustesDetails}
                            items={ajustesSections}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
}
