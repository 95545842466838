import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: '100%',
            '&>div': {
                display: 'flex',
                alignItems: 'center',
                minHeight: 50,
                paddingLeft: theme.spacing(6),
                paddingRight: theme.spacing(2),
            },
            '&>div:not(:first-of-type)': {
                borderTop: `1px solid ${theme.palette.divider}`,
            },
        },
        title: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
            '& strong': {
                fontWeight: 500,
            },
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiIconButton-root': {
                padding: theme.spacing(1),
            },
            '& svg': {
                fontSize: 20,
            },
        },
    }),
    { name: 'EditFormHeader' },
);

export default function EditFormHeader({ label, name, children, actions, className }) {
    const classes = useStyles();

    return (
        <Paper elevation={0} square className={clsx(classes.root, className)}>
            <div>
                <Typography variant='h3' className={classes.title}>
                    <strong>{label}:</strong> {name}
                </Typography>
                <div className={classes.actions}>{actions}</div>
            </div>
            {children}
        </Paper>
    );
}

EditFormHeader.propTypes = {
    actions: PropTypes.any,
    children: PropTypes.any.isRequired,
    className: PropTypes.any,
    label: PropTypes.string.isRequired,
    name: PropTypes.any.isRequired,
};
