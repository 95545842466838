import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ReplayIcon from '@material-ui/icons/Replay';
import { es } from 'date-fns/locale';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { calendariosProvider } from '../../api';
import { formatISODate } from '../../utils';
import Button from '../common/Button';
import { useFormStyles } from '../common/forms/EditForm';

const useStyles = makeStyles(
    (theme) => ({
        calendar: {
            '&.rdp': {
                margin: 0,
                fontSize: 12,
                '--rdp-cell-size': '32px',
                '--rdp-caption-font-size': '14px',
            },
            '& .rdp-months': {
                flexWrap: 'wrap',
                gap: `${theme.spacing(3)}px`,
            },
            '& .rdp-month': {
                margin: 0,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
        actions: {
            marginLeft: 'auto',
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
        },
    }),
    { name: 'CalendarForm' },
);

export default function CalendarForm() {
    const formClasses = useFormStyles();
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [hasChanged, setHasChanged] = useState(false);
    const [selectedDates, setSelectedDates] = useState([]);

    useEffect(() => {
        calendariosProvider.getNoLaborables(currentYear).then((dates) => {
            setSelectedDates(dates.map((d) => new Date(d)));
            setHasChanged(false);
        });
    }, [currentYear]);

    useEffect(() => {}, [selectedDates]);

    return (
        <Grid container className={formClasses.root} spacing={2}>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>El año</Typography>
                <IconButton onClick={() => setCurrentYear((year) => year - 1)}>
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <Typography>{currentYear}</Typography>
                <IconButton onClick={() => setCurrentYear((year) => year + 1)}>
                    <KeyboardArrowRightIcon />
                </IconButton>
                <Typography>{`tiene ${selectedDates.length} dias no laborables (incluidos fines de semana).`}</Typography>
                <div className={classes.actions}>
                    <Button
                        color='outlined'
                        onClick={() => {
                            calendariosProvider.resetNoLaborables(currentYear).then(({ dates }) => {
                                setSelectedDates(dates.map((d) => new Date(d)));
                                setHasChanged(false);
                                snackbar.showMessage('Se ha actualizado el calendario laboral');
                            });
                        }}
                        startIcon={<ReplayIcon />}
                    >
                        Reiniciar a solo fines de semana
                    </Button>
                    <Button
                        color='info'
                        onClick={() => {
                            calendariosProvider
                                .getAll(`${currentYear}`, {
                                    method: 'post',
                                    body: JSON.stringify({ dates: selectedDates.map(formatISODate) }),
                                })
                                .then(() => snackbar.showMessage('Se ha actualizado el calendario'));
                        }}
                        disabled={!hasChanged}
                    >
                        Guardar
                    </Button>
                </div>
            </Grid>
            <Grid item xs={12}>
                <DayPicker
                    className={classes.calendar}
                    disableNavigation
                    locale={es}
                    month={new Date(currentYear, 0)}
                    mode='multiple'
                    selected={selectedDates}
                    onSelect={(dates) => {
                        setSelectedDates(dates || []);
                        setHasChanged(true);
                    }}
                    numberOfMonths={12}
                />
            </Grid>

            {/* <Grid item xs={12}> */}
            {/*    {selectedDates.length > 0 && ( */}
            {/*        <Button */}
            {/*            color='info' */}
            {/*            onClick={() => setOpen(true)} */}
            {/*            style={{ marginRight: 16 }} */}
            {/*            startIcon={<EditIcon />} */}
            {/*        > */}
            {/*            Editar días no laborables */}
            {/*        </Button> */}
            {/*    )} */}
            {/*    {selectedDates.length === 0 && ( */}
            {/*        <Button */}
            {/*            color='info' */}
            {/*            onClick={() => { */}
            {/*                calendariosProvider.resetNoLaborables(currentYear).then(({ dates }) => { */}
            {/*                    setSelectedDates(dates.map((d) => new Date(d))); */}
            {/*                    snackbar.showMessage('Se ha actualizado el calendario laboral'); */}
            {/*                }); */}
            {/*            }} */}
            {/*            startIcon={<ReplayIcon />} */}
            {/*        > */}
            {/*            Reiniciar a solo fines de semana */}
            {/*        </Button> */}
            {/*    )} */}

            {/*    <MultipleDatesPicker */}
            {/*        open={open} */}
            {/*        selectedDates={selectedDates} */}
            {/*        initialDate={new Date(currentYear, 0, 1)} */}
            {/*        onCancel={() => setOpen(false)} */}
            {/*        onSubmit={(dates) => { */}
            {/*            calendariosProvider */}
            {/*                .getAll(`${currentYear}`, { */}
            {/*                    method: 'post', */}
            {/*                    body: JSON.stringify({ dates: dates.map(formatISODate) }), */}
            {/*                }) */}
            {/*                .then(() => snackbar.showMessage('Se ha actualizado el calendario')); */}
            {/*            setSelectedDates(dates); */}
            {/*            setOpen(false); */}
            {/*        }} */}
            {/*        cancelButtonText='Cancelar' */}
            {/*        submitButtonText='Guardar' */}
            {/*        selectedDatesTitle='Fechas seleccionadas' */}
            {/*    /> */}
            {/* </Grid> */}
        </Grid>
    );
}
