import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import { useCallback, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { companiesProvider } from '../../../api';
import { useFetchData } from '../../../hooks/useFetchData';
import Button from '../../common/Button';
import EditForm from '../../common/forms/EditForm/EditForm';
import { EditProfileFormContent } from './EditProfileFormContent';

export const DATOS_FACTURACION_ROUTE = 'datos-facturacion';
export const SUSCRIPCION_ROUTE = 'suscripcion';
export const USUARIOS_ROUTE = 'usuarios';
export const EVENTOS_ROUTE = 'eventos';
export const AJUSTES_ROUTE = 'ajustes';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            flex: 1,
        },
        version: {
            marginTop: 'auto',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: `${theme.spacing(2)}px`,
            padding: theme.spacing(2, 0),
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
        },
    }),
    { name: 'EditProfileForm' },
);

export default function EditProfileForm() {
    const classes = useStyles();
    const { isExact: isRouteExact, url } = useRouteMatch();
    const history = useHistory();

    const fetchDataFn = useCallback(() => companiesProvider.getProfile(), []);
    const { data, fetchData } = useFetchData(fetchDataFn, null);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!isRouteExact || !data) return;

        history.replace(`${url}/${DATOS_FACTURACION_ROUTE}`);
    }, [data, url, isRouteExact]);

    return (
        data && (
            <>
                <EditForm data={data} fetchData={fetchData} className={classes.root}>
                    <EditProfileFormContent />
                    <Typography variant='body2' className={classes.version}>
                        Version {process.env.REACT_APP_VERSION}
                        <Button
                            color='primary'
                            onClick={() => {
                                if (!navigator.serviceWorker) return;
                                navigator.serviceWorker.getRegistration().then((registration) => {
                                    if (!registration) return;

                                    console.debug('Manually checking for updates...');
                                    registration.update();
                                });
                            }}
                            startIcon={<SystemUpdateIcon />}
                        >
                            Comprobar actualizaciones
                        </Button>
                    </Typography>
                </EditForm>
            </>
        )
    );
}
