import { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'material-ui-snackbar-provider';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import { formatFullDate } from '../../utils';
import InfoField from '../common/fields/InfoField';
import { fichajesProvider } from '../../api';
import MarcajesField from './MarcajesField';
import DialogForm from '../common/forms/DialogForm';
import PropTypes from 'prop-types';

const FichajeSchema = Yup.object().shape({
    marcajes: Yup.array().min(1, 'Tiene que haber al menos un marcaje'),
});

export function EditFichajeDialog({ fichaje: originalFichaje, onSave, ...props }) {
    const snackbar = useSnackbar();

    const [fichaje, setFichaje] = useState(originalFichaje);
    useEffect(() => {
        setFichaje(originalFichaje);
    }, [originalFichaje]);

    return (
        <DialogForm
            title='Editar fichaje'
            FormikProps={{
                initialValues: {
                    marcajes: fichaje.marcajes,
                },
                enableReinitialize: true,
                validationSchema: FichajeSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    fichajesProvider
                        .save(values, fichaje.id)
                        .then((updatedFichaje) => {
                            setSubmitting(false);
                            onSave(updatedFichaje);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            } else {
                                snackbar.showMessage(err.toString());
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InfoField label='Operario' value={fichaje.operario} />
                        </Grid>
                        <Grid item xs={12}>
                            <InfoField label='Fecha' value={formatFullDate(fichaje.fecha)} />
                        </Grid>
                        <MarcajesField marcajes={values.marcajes} />
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

export function EditFichajeButton({ fichaje, onSave }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <IconButton onClick={() => setOpen(true)} style={{ padding: 6 }}>
                <EditIcon fontSize='small' />
            </IconButton>
            <EditFichajeDialog
                open={open}
                onSave={(res) => {
                    onSave(res);
                    setOpen(false);
                }}
                onClose={() => setOpen(false)}
                fichaje={fichaje}
            />
        </>
    );
}

EditFichajeDialog.propTypes = {
    fichaje: PropTypes.any,
    onSave: PropTypes.any,
};

EditFichajeButton.propTypes = {
    fichaje: PropTypes.any,
    onSave: PropTypes.any,
};
