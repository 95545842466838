import DataProvider from './DataProvider';

export const empty = {
    referencia_interna: '',
    descripcion: '',
    fecha: null,
    fecha_fin: null,
    cliente: null,
    categoria: null,
    operarios: [],
    estado: '',
    tipo: '',
    direccion: '',
    direccion_id: null,
    proyecto_id: null,
    cliente_id: null,
    has_notas_internas: false,
    notas_internas: false,
    detalles: '',
    jornadas: [],
    firmas: [],
    adjuntos: [],
    partes_mantenimiento: [],
    planificaciones: [],
    subtareas: [],
    numero: null,
    urgente: false,
    libre: false,
    checklists_tarea: [],
    documentos: [],
    minutos_estimados: 0,
};

export const estadoLabels = {
    SIN_PLANIFICAR: 'Nuevo',
    PENDIENTE: 'Sin empezar',
    EN_PROGRESO: 'En progreso',
    FINALIZADA: 'Terminado',
};

export const facturadoLabels = {
    NO_FACTURABLE: 'No facturable',
    NO_FACTURADO: 'No facturado',
    FACTURADO_PARCIALMENTE: 'Facturado parcialmente',
    FACTURADO: 'Facturado',
};

export const revisadoLabels = {
    NO_REVISADO: 'No revisado',
    REVISADO_PARCIALMENTE: 'Revisado parcialmente',
    REVISADO: 'Revisado',
};

export const tipoLabels = {
    AGENDA: 'Cita',
    INSTALACION: 'Instalación',
    MANTENIMIENTO: 'Mantenimiento',
    REPARACION: 'Reparación',
    SUMINISTRO: 'Suministro',
    PRESUPUESTO: 'Presupuesto',
    OTROS: 'Otros',
};

export const gastosLabels = {
    desplazamiento: 'Desplazamiento',
    dietas: 'Dietas',
    parking: 'Parking',
};

export const estadoIncidenciaLabels = {
    NO_RESUELTA: 'No resuelta',
    RESUELTA: 'Resuelta',
};

export const tipoOptions = Object.entries(tipoLabels).map(([value, label]) => ({ value, label }));
export const estadoTareaOptions = Object.entries(estadoLabels).map(([value, label]) => ({ value, label }));
export const facturadoOptions = Object.entries(facturadoLabels).map(([value, label]) => ({ value, label }));
export const revisadoOptions = Object.entries(revisadoLabels).map(([value, label]) => ({ value, label }));
export const estadoIncidenciaOptions = Object.entries(estadoIncidenciaLabels).map(([value, label]) => ({
    value,
    label,
}));

// export const dataProvider = new DataProvider('tareas', empty);

class TareasProvider extends DataProvider {
    constructor() {
        super('tareas', empty);
    }

    getByIdForList = async (id) => {
        return this.action(`${id}/lista`);
    };

    getByIdForCalendar = async (id) => {
        return this.action(`${id}/calendario`);
    };

    // save = (values, id) => {
    //     let url = `${API_ROOT}/${this.resourceName}/`;
    //     if (id) {
    //         url += id + '/from-list';
    //     }
    //
    //     return this._fetchJson(url, {
    //         method: 'post',
    //         body: values instanceof FormData ? values : JSON.stringify(values),
    //     });
    // };

    cerrar = async (id) => {
        return this.actionOnId(id, 'cerrar');
    };

    abrir = async (id) => {
        return this.actionOnId(id, 'abrir');
    };

    getNotasInternas = async (id) => {
        return this.actionOnId(id, 'notas_internas', null, { method: 'get' });
    };

    saveNotasInternas = async (id, notasInternas) => {
        return this.actionOnId(id, 'notas_internas', { notas_internas: notasInternas });
    };

    saveExplicacion = async (id, explicacion) => {
        return this.actionOnId(id, 'explicacion', { texto: explicacion });
    };

    saveJornadaExplicacion = async (jornadaId, explicacion) => {
        return this.getAll(`jornadas/${jornadaId}/explicacion`, {
            method: 'post',
            body: JSON.stringify({ texto: explicacion }),
        });
    };

    saveAlbaranExplicacion = async (albaranId, explicacion) => {
        return this.getAll(`albaranes/${albaranId}/explicacion`, {
            method: 'post',
            body: JSON.stringify({ texto: explicacion }),
        });
    };

    generarAlbaranPdf = (id, params) =>
        this.getAll(`albaranes/${id}/pdf-async?${new URLSearchParams(params).toString()}`);

    getAlbaranPdfURL = (id, params) =>
        this.generateUrl(`albaranes/${id}/pdf?${new URLSearchParams(params).toString()}`);

    getTareaPdfURL = (id, values) => this.generateUrl(`${id}/pdf?${new URLSearchParams(values).toString()}`);
    getMantenimientoPdfUrl = (id) => this.generateUrl(`${id}/mantenimiento-pdf`);
    getParteMantenimientoPdfUrl = (id) => this.generateUrl(`partes-mantenimiento/${id}/pdf`);

    getAsOptions = async () => {
        return this.getAll('as_options');
    };

    getSinPlanificar = async () => this.getAll('sin-planificar');
    getNuevas = async () => this.getAll('nuevas');
    getAbiertas = async () => this.getAll('abiertas');
    getCerradas = async () => this.getAll('cerradas');
    getTodas = async () => this.getAll('todas');
    getParaAlbaranes = async () => this.getAll('para-albaranes');
    getAlbaranes = async () => this.getAll('albaranes');
    getAlbaranesPendientesFacturar = async () => this.getAll('albaranes/pendientes-facturar');
    getAlbaranesVacios = async () => this.getAll('albaranes/vacios');

    updateTiempoJornada = async (
        jornadaId,
        tiempo,
        precioCoste,
        precioVenta,
        descuento,
        categoriaOperarioId,
        tipoHoraId,
    ) => {
        return this.getAll(`jornadas/${jornadaId}/tiempo`, {
            body: JSON.stringify({
                tiempo,
                precio_coste: precioCoste,
                precio_venta: precioVenta,
                descuento,
                categoria_operario_id: categoriaOperarioId,
                tipo_hora_id: tipoHoraId,
            }),
            method: 'post',
        });
    };

    updateMaterialJornada = async (jornadaId, materialId, material) => {
        let url = `jornadas/${jornadaId}/materiales`;
        if (materialId != null) url += `/${materialId}`;

        return this.getAll(url, {
            body: JSON.stringify(material),
            method: 'post',
        });
    };

    deleteMaterialJornada = async (jornadaId, materialId) => {
        return this.getAll(`jornadas/${jornadaId}/materiales/${materialId}`, { method: 'delete' });
    };

    updateJornadaNotaVista = async (jornadaId) => {
        return this.getAll(`jornadas/${jornadaId}/notas/vista`, { method: 'post' });
    };

    updateJornadaNotaIncidenciaResolucion = async (jornadaId, resolucion) => {
        return this.getAll(`jornadas/${jornadaId}/notas/resolucion`, {
            method: 'post',
            body: JSON.stringify({ resolucion }),
        });
    };

    updateJornadaNotaIncidenciaResuelta = async (jornadaId, resuelta) => {
        return this.getAll(`jornadas/${jornadaId}/notas/resolucion`, {
            method: 'post',
            body: JSON.stringify({ resuelta }),
        });
    };

    deleteJornadaNotas = async (jornadaId) => {
        return this.getAll(`jornadas/${jornadaId}/notas`, { method: 'delete' });
    };

    deleteFoto = async (fotoId) => {
        return this.getAll(`fotos/${fotoId}`, { method: 'delete' });
    };

    exportarCerradas = () =>
        this.fetchRaw('cerradas/exportar', { method: 'post' }).then(async (data) => {
            const uri = window.URL.createObjectURL(await data.blob());

            const a = document.createElement('a');
            a.style = { display: 'none' };
            a.href = uri;
            a.download = data.headers.get('Content-Disposition').split('filename=')[1];
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(uri);
            document.body.removeChild(a);
        });

    generarAlbaran = async (tareaId, selectedJornadas) => {
        return this.actionOnId(tareaId, 'albaranes', { ids: selectedJornadas });
    };

    marcarAlbaranEnviado = async (albaranId, enviado) => {
        return this.getAll(`albaranes/${albaranId}/enviado`, {
            method: 'post',
            body: JSON.stringify({ enviado }),
        });
    };

    marcarAlbaranFacturado = async (albaranId, facturado) => {
        return this.getAll(`albaranes/${albaranId}/facturado`, {
            method: 'post',
            body: JSON.stringify({ facturado }),
        });
    };

    addJornadasToAlbaran = async (albaranId, selectedJornadas) => {
        return this.action(`albaranes/${albaranId}/jornadas`, {
            method: 'post',
            body: JSON.stringify({ ids: selectedJornadas }),
        });
    };

    deleteJornadaFromAlbaran = async (albaranId, jornadaId) => {
        return this.getAll(`albaranes/${albaranId}/jornadas/${jornadaId}`, {
            method: 'delete',
        });
    };

    getAlbaranEnvioInfo = async (albaranId) => {
        return this.getAll(`albaranes/${albaranId}/envio-info`);
    };

    enviarAlbaran = async (albaranId, envioInfo) => {
        return this.getAll(`albaranes/${albaranId}/enviar-async`, {
            method: 'post',
            body: JSON.stringify(envioInfo),
        });
    };

    marcarJornadaRevisada = async (jornadaId, revisada = true) => {
        return this.action(`jornadas/${jornadaId}/revisar`, {
            method: 'post',
            body: JSON.stringify({
                revisada,
            }),
        });
    };

    getMantenimientos = async (year) => this.getAll(`mantenimientos/${year}`);

    getPreciosGastos = async (tareaId) => this.getAll(`${tareaId}/precios-gastos`);
    setPreciosGastos = async (tareaId, preciosGastos) => {
        return this.getAll(`${tareaId}/precios-gastos`, {
            method: 'post',
            body: JSON.stringify(preciosGastos),
        });
    };

    marcarFacturable = async (tareaId, facturable) => {
        return this.getAll(`${tareaId}/facturable`, {
            method: 'post',
            body: JSON.stringify({ facturable }),
        });
    };

    getIncidencias = async (tareaId) => this.getAll('incidencias');

    search = async (query, estados) => {
        return this.getAll('buscar', {
            method: 'post',
            body: JSON.stringify({ query, estados }),
        });
    };
}

export const dataProvider = new TareasProvider();
