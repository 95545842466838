import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { operariosProvider } from '../../api';
import { ADMINISTRATIVO, getDefaultPermisos, OPERARIO, OPERARIO_ADMINISTRATIVO, tipoLabels } from '../../api/operarios';
import useAuthState from '../../AuthState';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import Button from '../common/Button';
import Stepper from '../tareas/Stepper';
import NuevoDatosGeneralesForm from './OperarioDialog/NuevoDatosGeneralesForm';
import PermisosAdministrativo from './OperarioDialog/PermisosAdministrativo';
import PermisosOperario from './OperarioDialog/PermisosOperario';

const OperariosSchema = Yup.object().shape({
    codigo: Yup.number().required('Requerido'),
    nombre: Yup.string().required('Requerido'),
    user: Yup.object().shape({
        username: Yup.string().required('Requerido'),
        email: Yup.string().required('Requerido'),
        password: Yup.string().required('Requerido'),
        confirm_password: Yup.string()
            .required('Requerido')
            .oneOf([Yup.ref('password'), null], 'Las contraseñas tienen que ser iguales'),
    }),
});

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 800,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
    }),
    { name: 'NuevoOperarioDialog' },
);

function NuevoOperarioDialog({ open, onClose, onSave, tipo }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const { isAdministrativo } = useAuthState();

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby='nueva-operario-title'
            open={open}
            classes={{
                paper: classes.root,
            }}
            fullWidth={false}
            maxWidth='lg'
        >
            <Formik
                initialValues={{
                    codigo: '',
                    nombre: '',
                    telefono: '',
                    tipo,
                    nif: '',
                    naf: '',
                    activo: true,
                    is_admin: false,
                    user: {
                        email: '',
                        username: '',
                        password: '',
                        confirm_password: '',
                    },
                    categoria_operario: null,
                    fecha_alta: new Date(),
                    fecha_baja: null,
                    permisos: getDefaultPermisos(OPERARIO_ADMINISTRATIVO),
                }}
                enableReinitialize
                validationSchema={OperariosSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                    operariosProvider
                        .save(values)
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            onClose();
                            snackbar.showMessage('El empleado se ha creado con éxito');
                        })
                        .catch((err) => {
                            function processErrors(obj, baseKey) {
                                for (const [field, errors] of Object.entries(obj)) {
                                    let fieldName = field;
                                    if (baseKey) fieldName = `${baseKey}.${fieldName}`;
                                    if (Array.isArray(errors)) {
                                        setFieldError(fieldName, errors.join('\n'));
                                    } else {
                                        processErrors(errors, fieldName);
                                    }
                                }
                            }

                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    processErrors(err.message);
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            } else {
                                snackbar.showMessage('Ha ocurrido un error');
                            }
                            setSubmitting(false);
                        });
                }}
            >
                {({ values: { nombre, tipo }, isSubmitting, setFieldValue, touched }) => {
                    const [activeStep, setActiveStep] = useState(0);

                    const steps = [{ label: 'Datos generales' }];
                    if (!isAdministrativo) {
                        if (tipo === OPERARIO || tipo === ADMINISTRATIVO) {
                            steps.push({ label: 'Permisos' });
                        } else {
                            steps.push({ label: 'Permisos operario' }, { label: 'Permisos administrativo' });
                        }
                    }
                    const lastStep = steps.length - 1;

                    const usernameTouched = touched.user && touched.user.username;
                    useEffect(() => {
                        if (usernameTouched) return;

                        setFieldValue('user.username', nombre.toLowerCase().replace(/ /g, '.'));
                    }, [nombre, usernameTouched]);

                    return (
                        <Form className={classes.form}>
                            <DialogTitle id='nueva-operario-title'>Añadir {tipoLabels[tipo]}</DialogTitle>
                            <DialogContent>
                                {steps.length > 1 && (
                                    <Stepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
                                )}
                                <NuevoDatosGeneralesForm tipo={tipo} hidden={activeStep !== 0} />
                                {tipo === OPERARIO && <PermisosOperario hidden={activeStep !== 1} />}
                                {tipo === ADMINISTRATIVO && <PermisosAdministrativo hidden={activeStep !== 1} />}
                                {tipo === OPERARIO_ADMINISTRATIVO && (
                                    <>
                                        <PermisosOperario hidden={activeStep !== 1} />
                                        <PermisosAdministrativo hidden={activeStep !== 2} />
                                    </>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button color='outlined' disabled={isSubmitting} onClick={onClose}>
                                    Cancelar
                                </Button>
                                {activeStep > 0 && (
                                    <Button color='dark' onClick={() => setActiveStep((activeStep) => activeStep - 1)}>
                                        Atrás
                                    </Button>
                                )}
                                {activeStep < lastStep && (
                                    <Button color='info' onClick={() => setActiveStep((activeStep) => activeStep + 1)}>
                                        Siguiente
                                    </Button>
                                )}
                                {activeStep === lastStep && (
                                    <Button type='submit' color='info' disabled={isSubmitting}>
                                        Guardar
                                    </Button>
                                )}
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}

export default withButtonOpener(NuevoOperarioDialog);

NuevoOperarioDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
    tipo: PropTypes.string,
};
