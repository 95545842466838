import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { presupuestosProvider } from '../../../api';
import { tipoUnidadesOptions } from '../../../api/presupuestos';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import SelectField from '../../common/fields/SelectField';
import DialogForm from '../../common/forms/DialogForm';
import ReferenciaField from './ReferenciaField';

const PartidaSchema = Yup.object().shape({
    referencia: Yup.string(),
    concepto: Yup.string().required('Requerido'),
    unidades: Yup.number()
        .required('Requerido')
        .test('gt-0', 'Debe ser mayor que 0', (value) => value > 0),
});

function AddPartidaDialog({ presupuestoId, parentId, onSave, ...props }) {
    const match = useRouteMatch();
    const proyectoId = match.url.startsWith('/proyectos') ? match.params.id : null;
    const [duplicateLineaId, setDuplicateLineaId] = useState(null);

    return (
        <DialogForm
            title='Añadir partida'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    referencia: '',
                    concepto: '',
                    unidades: 1,
                    tipo_unidades: 'UNIDADES',
                    descripcion: '',
                    observaciones: '',
                },
                validationSchema: PartidaSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    values.tipo_linea = 'PARTIDA';
                    values.parent_id = parentId;
                    values.duplicate_linea_id = duplicateLineaId;

                    presupuestosProvider
                        .addLinea(presupuestoId, values)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ setFieldValue }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <ReferenciaField
                                proyectoId={proyectoId}
                                presupuestoId={presupuestoId}
                                tipoLinea='PARTIDA'
                                onSelect={(linea) => {
                                    setFieldValue('referencia', linea.referencia);
                                    setFieldValue('concepto', linea.concepto);
                                    setFieldValue('tipo_unidades', linea.tipo_unidades);
                                    setFieldValue('descripcion', linea.descripcion);
                                    setDuplicateLineaId(linea.id);
                                }}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <TextField name='concepto' label='Concepto' fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField name='unidades' label='Unidades' fullWidth type='number' />
                        </Grid>
                        <Grid item xs={9}>
                            <Field
                                name='tipo_unidades'
                                id='tipo_unidades'
                                component={SelectField}
                                label='Tipo de unidades'
                                fullWidth
                                selectOptions={tipoUnidadesOptions}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='descripcion'
                                label='Descripción'
                                fullWidth
                                multiline
                                minRows={4}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                multiline
                                minRows={4}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

AddPartidaDialog.propTypes = {
    presupuestoId: PropTypes.string,
    parentId: PropTypes.string,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
};

export default withButtonOpener(AddPartidaDialog);
