import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import SelectField from '../../common/fields/SelectField';
import { SwitchWithLabel } from '../../common/fields/Switch';

export const tipoMarcajeOptions = [
    { value: '', label: 'Por defecto (según Ajustes)', realValue: null },
    { value: 'true', label: 'Marcar hora de entrada y salida', realValue: true },
    { value: 'false', label: 'Marcar tiempo total', realValue: false },
];

export default function PermisosOperario({ hidden }) {
    const hiddenStyle = hidden ? { display: 'none' } : {};

    return (
        <Grid container spacing={2} style={hiddenStyle}>
            <Grid item xs={12}>
                <Field name='permisos.crear_servicios' component={SwitchWithLabel} label='Crear servicios' />
            </Grid>
            <Grid item xs={12}>
                <Field name='is_admin' component={SwitchWithLabel} label='Editar servicios' />
            </Grid>
            <Grid item xs={12}>
                <Field name='permisos.cerrar_servicios' component={SwitchWithLabel} label='Cerrar servicios' />
            </Grid>
            <Grid item xs={12}>
                <Field name='permisos.generar_albaranes' component={SwitchWithLabel} label='Generar albaranes' />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name='permisos.enviar_albaranes'
                    component={SwitchWithLabel}
                    label='Enviar albaranes firmados a cliente'
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name='es_responsable_operarios'
                    component={SwitchWithLabel}
                    label='Responsable de operarios (ver calendario de planificación en la App)'
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name='es_responsable_proyectos'
                    component={SwitchWithLabel}
                    label='Responsable de proyectos (ver lista de proyectos en la App)'
                />
            </Grid>
            {/* <Grid item xs={12}> */}
            {/*    <Field */}
            {/*        name='permisos.recibir_copia_albaranes_propios' */}
            {/*        component={SwitchWithLabel} */}
            {/*        label='Enviar al operario copia de albaranes que contengan partes de trabajo propios' */}
            {/*    /> */}
            {/* </Grid> */}
            <Grid item xs={12}>
                <Field name='permisos.fichar' component={SwitchWithLabel} label='Puede fichar' />
            </Grid>
            <Grid item xs={12}>
                <Field name='permisos.ver_documentacion' component={SwitchWithLabel} label='Ver documentación' />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name='permisos.marcar_entrada_salida'
                    id='marcar_entrada_salida'
                    component={SelectField}
                    label='Cómo registrar mano de obra en los partes de trabajo'
                    fullWidth
                    selectOptions={tipoMarcajeOptions}
                />
            </Grid>
        </Grid>
    );
}

PermisosOperario.propTypes = {
    hidden: PropTypes.bool.isRequired,
};
