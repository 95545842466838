import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EditIcon from '@material-ui/icons/Edit';
import { useCallback, useEffect, useMemo } from 'react';
import { albaranesCompraProvider } from '../../api';
import {
    estadoDevolucionLabels,
    estadoDevolucionOptions,
    estadoRevisionLabels,
    estadoRevisionOptions,
} from '../../api/albaranes_compra';
import { estadoCompraLabels, estadoCompraOptions } from '../../api/preparaciones_material';
import { estadoLabels, estadoTareaOptions } from '../../api/tareas';
import { COLORS } from '../../App';
import { useFetchData } from '../../hooks/useFetchData';
import { formatCurrency, formatDate } from '../../utils';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../common/list/ListView';
import { multipleSelectFilterFn, SelectColumnFilter } from '../common/list/SelectColumnFilter';
import CompraMaterialEstadoChip from '../servicios/CompraMaterialEstadoChip';
import ServicioEstadoChip from '../servicios/ServicioEstadoChip';
import EditRevisionLineaDialog from './EditRevisionLineaDialog';
import EstadoDevolucionChip from './EstadoDevolucionChip';
import EstadoRevisionChip from './EstadoRevisionChip';
import PreciosCell from './PreciosCell';

const ListView = withTableState('revision_material', BaseListView);

const useStyles = makeStyles(
    (theme) => ({
        revisionTable: {
            '&>thead>tr>th:nth-child(-n+6), &>tbody>tr>td:nth-child(-n+6)': {
                background: theme.palette.neutral.hoverCell,
            },
            '&>thead>tr>th:nth-last-child(-n+11), &>tbody>tr>td:nth-last-child(-n+11)': {
                background: theme.palette.neutral.hoverCell,
            },
        },
    }),
    { name: 'RevisionMaterial' },
);

export default function RevisionMaterial() {
    const classes = useStyles();
    const fetchDataFn = useCallback(() => albaranesCompraProvider.getAll('revision-material'), []);
    const { data, fetchData, setData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useMemo(
        () => [
            {
                Header: 'Albarán de compra',
                style: {
                    textAlign: 'center',
                    background: COLORS.neutral.hoverCell,
                },
                columns: [
                    {
                        Header: 'Referencia',
                        accessor: 'ref_albaran',
                    },
                    {
                        Header: 'Proveedor',
                        accessor: 'proveedor',
                    },
                    {
                        Header: 'Fecha compra',
                        accessor: 'fecha_compra',
                        Filter: DateColumnFilter,
                        filter: dateFilterFn,
                        Cell: ({ value }) => formatDate(value),
                    },
                    {
                        Header: 'Fecha factura',
                        accessor: 'fecha_fra_compra',
                        Filter: DateColumnFilter,
                        filter: dateFilterFn,
                        Cell: ({ value }) => (value ? formatDate(value) : 'Sin fecha'),
                    },
                    {
                        Header: 'Núm. factura',
                        accessor: 'num_fra_compra',
                    },
                    {
                        Header: 'Estado compra',
                        accessor: 'estado_compra',
                        // eslint-disable-next-line react/prop-types
                        Cell: ({ value }) => <CompraMaterialEstadoChip value={value} />,
                        Filter: SelectColumnFilter,
                        filter: 'equals',
                        filterOptions: {
                            options: estadoCompraOptions,
                            includeAllOption: {
                                value: 'todos',
                                label: 'Todos',
                            },
                        },
                        exportValue: ({ value }) => estadoCompraLabels[value],
                    },
                ],
            },
            {
                Header: 'Servicio',
                style: {
                    textAlign: 'center',
                    background: 'white',
                },
                columns: [
                    {
                        Header: 'Núm. servicio',
                        accessor: 'num_tarea',
                    },
                    {
                        Header: 'Ref. servicio',
                        accessor: 'ref_tarea',
                    },
                    {
                        Header: 'Cliente',
                        accessor: 'cliente',
                        noWrap: true,
                    },
                    {
                        Header: 'Proyecto',
                        accessor: 'proyecto',
                        noWrap: true,
                    },
                    {
                        Header: 'Estado servicio',
                        accessor: 'estado_tarea',
                        Cell: ServicioEstadoChip,
                        Filter: SelectColumnFilter,
                        filter: multipleSelectFilterFn,
                        filterOptions: {
                            options: estadoTareaOptions,
                            multiple: true,
                        },
                        exportValue: ({ value }) => estadoLabels[value],
                    },
                ],
            },
            {
                Header: 'Material',
                align: 'center',
                style: {
                    textAlign: 'center',
                    background: COLORS.neutral.hoverCell,
                },
                columns: [
                    {
                        Header: 'Ref. material',
                        accessor: 'ref_material',
                    },
                    {
                        Header: 'Descripción',
                        accessor: 'descripcion',
                        noWrap: true,
                    },
                    {
                        Header: 'Uds. compra',
                        accessor: 'uds_compra',
                    },
                    {
                        Header: 'Precio compra',
                        accessor: 'precio_unitario',
                        // eslint-disable-next-line react/prop-types
                        Cell: ({ row }) => <PreciosCell row={row} setData={setData} />,
                    },
                    {
                        Header: 'Dto. compra',
                        accessor: 'dto_compra',
                        Cell: ({ value }) => `${value}%`,
                    },
                    {
                        Header: 'Precio final',
                        id: 'precio_final',
                        accessor: (row) => row.precio_unitario * (1 - row.dto_compra / 100),
                        Cell: ({ value }) => formatCurrency(value, 2, 4),
                    },
                    {
                        Header: 'Importe',
                        id: 'importe',
                        accessor: (row) => row.precio_unitario * (1 - row.dto_compra / 100) * row.uds_compra,
                        Cell: ({ value }) => formatCurrency(value, 2),
                    },
                    {
                        Header: 'Uds. imputadas',
                        accessor: 'uds_jornada',
                    },
                    {
                        Header: 'Fecha imputación',
                        accessor: 'fecha_jornada',
                        Filter: DateColumnFilter,
                        filter: dateFilterFn,
                        Cell: ({ value }) => formatDate(value),
                    },
                    {
                        Header: 'Estado revisión',
                        accessor: 'estado_revision',
                        Cell: EstadoRevisionChip,
                        Filter: SelectColumnFilter,
                        filter: multipleSelectFilterFn,
                        filterOptions: {
                            options: estadoRevisionOptions,
                            multiple: true,
                        },
                        exportValue: ({ value }) => estadoRevisionLabels[value],
                    },
                    {
                        Header: 'Estado devolución',
                        accessor: 'estado_devolucion',
                        Cell: EstadoDevolucionChip,
                        Filter: SelectColumnFilter,
                        filter: multipleSelectFilterFn,
                        filterOptions: {
                            options: estadoDevolucionOptions,
                            multiple: true,
                        },
                        exportValue: ({ value }) => estadoDevolucionLabels[value],
                    },
                    {
                        Header: 'Etiquetas',
                        id: 'tags',
                        accessor: (row) => row.tags && row.tags.map((tag) => tag.nombre).join(', '),
                    },
                ],
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideDelete: true,
            hideEdit: true,
            tableOptions: {
                className: classes.revisionTable,
            },
        }),
        [],
    );

    return (
        <ListView
            title='Revisión de material'
            tableTitle='Lista de material imputado a servicios'
            basePath='/revision-material'
            columns={columns}
            data={data}
            options={options}
            className={classes.root}
            extraActions={(row) => (
                <EditRevisionLineaDialog
                    linea={row}
                    button={
                        <IconButton>
                            <EditIcon />
                        </IconButton>
                    }
                    tooltip='Editar revisión'
                    onSave={(linea) =>
                        setData((lineas) => {
                            return lineas.map((l) => (l.id === linea.id ? linea : l));
                        })
                    }
                />
            )}
        />
    );
}
