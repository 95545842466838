import { useDroppable } from '@dnd-kit/core';
import addDays from 'date-fns/addDays';
import PropTypes from 'prop-types';
import { groupByToKey } from '../agenda/useTransformEvents';
import { SIN_ASIGNAR, useCalendarioPlanificacion } from './CalendarioContent';
import { EVENTO_DRAGGABLE_TYPE } from './Evento';

export const DAY_DROPPABLE_TYPE = 'DAY';
export default function DayPlaceholder({ group, day, numRows, columnStart }) {
    const groupId = group ? group.id : SIN_ASIGNAR;
    const { startDate } = useCalendarioPlanificacion();
    const { setNodeRef, isOver, active } = useDroppable({
        id: `${groupId}:${day}`,
        data: {
            type: DAY_DROPPABLE_TYPE,
            groupId,
            group,
            day,
            date: addDays(startDate, day),
        },
    });

    const currentActive = active?.data?.current;
    const overStyle =
        isOver &&
        currentActive &&
        currentActive.type === EVENTO_DRAGGABLE_TYPE &&
        (currentActive.entity.offset !== day ||
            currentActive.entity[groupByToKey[currentActive.entity.groupBy]] !== groupId)
            ? {
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  borderRadius: 4,
                  zIndex: 9999,
              }
            : {};

    return (
        <div
            className='pepe'
            ref={setNodeRef}
            style={{
                gridColumnStart: columnStart,
                gridColumnEnd: 'span 1',
                gridRowStart: 1,
                gridRowEnd: `span ${numRows}`,
                ...overStyle,
            }}
        ></div>
    );
}

DayPlaceholder.propTypes = {
    group: PropTypes.any,
    day: PropTypes.any,
    numRows: PropTypes.number,
    columnStart: PropTypes.number,
};
