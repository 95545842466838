import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { operariosProvider } from '../../api';
import { ADMINISTRATIVO, getDefaultPermisos, OPERARIO, OPERARIO_ADMINISTRATIVO, tipoLabels } from '../../api/operarios';
import useAuthState from '../../AuthState';
import { useFetchData } from '../../hooks/useFetchData';
import useInfoSuscripcion from '../../hooks/useInfoSuscripcion';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import Button from '../common/Button';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import Stepper from '../tareas/Stepper';
import EditarDatosGeneralesForm from './OperarioDialog/EditarDatosGeneralesForm';
import PermisosAdministrativo from './OperarioDialog/PermisosAdministrativo';
import PermisosOperario, { tipoMarcajeOptions } from './OperarioDialog/PermisosOperario';

const OperariosSchema = Yup.object().shape({
    codigo: Yup.number().required('Requerido'),
    nombre: Yup.string().required('Requerido'),
    email: Yup.string().required('Requerido'),
    fecha_alta: Yup.date().nullable(),
    fecha_baja: Yup.date().nullable(),
});

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 800,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        activoField: {
            display: 'flex',
            flexDirection: 'column',
        },
        licenciasAgotadas: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            marginTop: -4,
            gap: `${theme.spacing(0.5)}px`,
            '& svg': {
                fontSize: 18,
            },
        },
    }),
    { name: 'EditOperarioDialog' },
);

export default withButtonOpener(function EditOperarioDialog(props) {
    const { data, fetchData } = useEditFormData();

    return <BaseEditOperarioDialog data={data} fetchData={fetchData} {...props} />;
});

export const EditOperarioDialogButton = withButtonOpener(function ({ operarioId, open, ...props }) {
    const fetchDataFn = useCallback(open ? () => operariosProvider.getAll(operarioId) : null, [open, operarioId]);

    const { data, fetchData } = useFetchData(fetchDataFn, null);

    useEffect(() => {
        if (open) fetchData();
    }, [open, fetchData]);

    return data && <BaseEditOperarioDialog data={data} fetchData={fetchData} open={open} {...props} />;
});

export function BaseEditOperarioDialog({ open, onClose, data, fetchData }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const {
        id: operarioId,
        codigo,
        nombre,
        email,
        telefono,
        tipo,
        nif,
        naf,
        activo,
        is_admin: isAdmin,
        precio_coste: precioCoste,
        precio_venta: precioVenta,
        usar_nueva_ui_operarios: usarNuevaUiOperarios,
        es_responsable_operarios: esResponsableOperarios,
        es_responsable_proyectos: esResponsableProyectos,
        categoria_operario_id: categoriaOperarioId,
        permisos,
    } = data;

    const { isAdministrativo } = useAuthState();
    const { agotadas } = useInfoSuscripcion([data]);

    const [activeStep, setActiveStep] = useState(0);

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby='nueva-operario-title'
            open={open}
            classes={{
                paper: classes.root,
            }}
            fullWidth={false}
            maxWidth='lg'
        >
            <Formik
                initialValues={{
                    codigo,
                    nombre,
                    email,
                    telefono,
                    nif,
                    naf,
                    activo,
                    tipo,
                    is_admin: isAdmin,
                    precio_coste: precioCoste,
                    precio_venta: precioVenta,
                    usar_nueva_ui_operarios: usarNuevaUiOperarios,
                    es_responsable_operarios: esResponsableOperarios,
                    es_responsable_proyectos: esResponsableProyectos,
                    categoria_operario: categoriaOperarioId ? { id: categoriaOperarioId } : null,
                    fecha_alta: data.fecha_alta ? new Date(data.fecha_alta) : null,
                    fecha_baja: data.fecha_baja ? new Date(data.fecha_baja) : null,
                    permisos: {
                        ...getDefaultPermisos(OPERARIO_ADMINISTRATIVO),
                        ...permisos,
                        marcar_entrada_salida: (
                            tipoMarcajeOptions.find((op) => op.realValue === permisos.marcar_entrada_salida) ||
                            tipoMarcajeOptions[0]
                        ).value,
                    },
                }}
                enableReinitialize
                validationSchema={OperariosSchema}
                onSubmit={(
                    { categoria_operario: categoria, fechaAlta, fechaBaja, permisos, ...values },
                    { setSubmitting, setFieldError },
                ) => {
                    operariosProvider
                        .save(
                            {
                                ...values,
                                categoria_operario_id: categoria ? categoria.id : null,
                                permisos: {
                                    ...permisos,
                                    marcar_entrada_salida: tipoMarcajeOptions.find(
                                        (op) => op.value === permisos.marcar_entrada_salida,
                                    ).realValue,
                                },
                            },
                            operarioId,
                        )
                        .then(() => {
                            fetchData();
                            setSubmitting(false);
                            onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            function processErrors(obj, baseKey) {
                                for (const [field, errors] of Object.entries(obj)) {
                                    let fieldName = field;
                                    if (baseKey) fieldName = `${baseKey}.${fieldName}`;
                                    if (Array.isArray(errors)) {
                                        setFieldError(fieldName, errors.join('\n'));
                                    } else {
                                        processErrors(errors, fieldName);
                                    }
                                }
                            }

                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    processErrors(err.message);
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            } else {
                                snackbar.showMessage('Ha ocurrido un error');
                            }
                            setSubmitting(false);
                        });
                }}
            >
                {({ isSubmitting, values: { tipo } }) => {
                    const steps = [{ label: 'Datos generales' }];
                    if (!isAdministrativo) {
                        if (tipo === OPERARIO || tipo === ADMINISTRATIVO) {
                            steps.push({ label: 'Permisos' });
                        } else {
                            steps.push({ label: 'Permisos operario' }, { label: 'Permisos administrativo' });
                        }
                    }
                    const lastStep = steps.length - 1;

                    return (
                        <Form className={classes.form}>
                            <DialogTitle id='nueva-operario-title'>Editar {tipoLabels[tipo]}</DialogTitle>
                            <DialogContent>
                                {steps.length > 1 && (
                                    <Stepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
                                )}
                                <EditarDatosGeneralesForm
                                    tipo={tipo}
                                    licenciasAgotadas={agotadas}
                                    activo={activo}
                                    hidden={activeStep !== 0}
                                />
                                {tipo === OPERARIO && <PermisosOperario hidden={activeStep !== 1} />}
                                {tipo === ADMINISTRATIVO && <PermisosAdministrativo hidden={activeStep !== 1} />}
                                {tipo === OPERARIO_ADMINISTRATIVO && (
                                    <>
                                        <PermisosOperario hidden={activeStep !== 1} />
                                        <PermisosAdministrativo hidden={activeStep !== 2} />
                                    </>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button color='outlined' disabled={isSubmitting} onClick={onClose}>
                                    Cancelar
                                </Button>
                                {activeStep > 0 && (
                                    <Button color='dark' onClick={() => setActiveStep((activeStep) => activeStep - 1)}>
                                        Atrás
                                    </Button>
                                )}
                                {activeStep < lastStep && (
                                    <Button color='info' onClick={() => setActiveStep((activeStep) => activeStep + 1)}>
                                        Siguiente
                                    </Button>
                                )}
                                {activeStep === lastStep && (
                                    <Button type='submit' color='info' disabled={isSubmitting}>
                                        Guardar
                                    </Button>
                                )}
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}

BaseEditOperarioDialog.propTypes = {
    onClose: PropTypes.any,
    open: PropTypes.any,
    data: PropTypes.any,
    fetchData: PropTypes.any,
};
