import { useEffect, useMemo, useState } from 'react';
import { materialesProvider } from '../../../api';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import useAuthState from '../../../AuthState';
import DialogForm from '../../common/forms/DialogForm';
import * as Yup from 'yup';
import { Field, useField, useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { Autocomplete } from 'formik-material-ui-lab';
import SearchIcon from '@material-ui/icons/Search';
import MuiTextField from '@material-ui/core/TextField';
import { TextField } from 'formik-material-ui';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 650,
        },
        searchIcon: {
            fontSize: 20,
            color: theme.palette.neutral.primary,
        },
        fields: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            flex: 1,
        },
    }),
    { name: 'MaterialEditor' },
);

function MaterialEditorContent({ open, material, showCoste }) {
    const classes = useStyles();
    const [materialesOptions, setMaterialesOptions] = useState([]);

    const isAlbaranCompra =
        material && material.albaran_compra_linea_id !== null && material.albaran_compra_linea_id !== undefined;

    const {
        userInfo: { preferencias: { usar_precios: usarPrecios } = {} },
    } = useAuthState();

    const { setFieldValue } = useFormikContext();
    const [{ value: materialInternoValue }, , { setValue: setMaterialInternoValue }] = useField('material_interno');

    useEffect(() => {
        if (!open || isAlbaranCompra || materialesOptions.length > 0) return;

        materialesProvider.getAsOptions().then(setMaterialesOptions);
    }, [open]);

    useEffect(() => {
        if (materialesOptions.length === 0) return;

        setMaterialInternoValue(
            materialInternoValue ? materialesOptions.find((d) => d.id === materialInternoValue.id) : null,
        );
    }, [materialInternoValue, materialesOptions]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Field
                    name='material_interno'
                    fullWidth
                    component={Autocomplete}
                    options={materialesOptions}
                    getOptionSelected={(option, value) => option.id === value?.id}
                    getOptionLabel={(option) => option?.descripcion ?? ''}
                    renderInput={({ InputProps, ...params }) => {
                        return (
                            <MuiTextField
                                fullWidth
                                InputProps={{
                                    ...InputProps,
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <SearchIcon className={classes.searchIcon} />
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder='Buscar materiales por referencia o descripción'
                                {...params}
                            />
                        );
                    }}
                    onChange={(ev, newValue) => {
                        setFieldValue('material_interno', newValue);
                        if (newValue) {
                            setFieldValue('descripcion', newValue.descripcion, false);
                            setFieldValue('precio_unitario_coste', newValue.precio_unitario_coste ?? 0, false);
                            setFieldValue('precio_unitario', newValue.precio_unitario, false);
                            setFieldValue('descuento', newValue.descuento, false);
                        }
                    }}
                    disabled={isAlbaranCompra}
                />
            </Grid>
            <Grid item xs={12} className={classes.fields}>
                <TextField
                    name='descripcion'
                    label='Descripción'
                    className={classes.field}
                    disabled={isAlbaranCompra}
                />
                <TextField name='unidades' label='Unidades' style={{ width: 80 }} disabled={isAlbaranCompra} />
            </Grid>
            {usarPrecios && (
                <Grid item xs={12} className={classes.fields}>
                    {showCoste && (
                        <TextField
                            name='precio_unitario_coste'
                            label='Precio coste'
                            type='number'
                            step='.01'
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                            }}
                            disabled={isAlbaranCompra}
                        />
                    )}
                    <TextField
                        name='precio_unitario'
                        label={showCoste ? 'Precio venta' : 'Precio'}
                        type='number'
                        step='.01'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                        }}
                    />
                    <TextField
                        name='descuento'
                        label={showCoste ? 'Dto venta' : 'Dto'}
                        type='number'
                        step='.01'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default function MaterialEditor({ material, onSave, showCoste = true, ...props }) {
    const { userInfo: { preferencias: { usar_telematel: usarTelematel = false } = {} } = {} } = useAuthState();

    const MaterialSchema = useMemo(
        () =>
            Yup.object().shape({
                descripcion: Yup.string().required('Requerido'),
                unidades: (usarTelematel ? Yup.number().typeError('Numérico') : Yup.string()).required('Requerido'),
            }),
        [usarTelematel],
    );

    return (
        <DialogForm
            title='Editar material'
            FormikProps={{
                initialValues: {
                    descripcion: material?.descripcion ?? '',
                    unidades: material?.unidades ?? 0,
                    precio_unitario_coste: material?.precio_unitario_coste ?? 0,
                    precio_unitario: material?.precio_unitario ?? 0,
                    descuento: material?.descuento ?? 0,
                    material_interno:
                        material && material.material_interno_id ? { id: material.material_interno_id } : null,
                },
                validationSchema: MaterialSchema,
                onSubmit: ({ material_interno: materialInterno, ...values }, { setSubmitting, setFieldError }) => {
                    onSave({ ...values, material_interno_id: materialInterno ? materialInterno.id : null }).catch(
                        (err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        },
                    );
                },
            }}
            {...props}
        >
            {() => <MaterialEditorContent open={props.open} material={material} showCoste={showCoste} />}
        </DialogForm>
    );
}

MaterialEditor.propTypes = {
    material: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
    showCoste: PropTypes.any,
};

MaterialEditorContent.propTypes = {
    showCoste: PropTypes.any,
    material: PropTypes.any,
    open: PropTypes.any,
};
