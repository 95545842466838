import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InfoIcon from '@material-ui/icons/Info';
import { useFormikContext } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ADMINISTRATIVO, OPERARIO, tipoLabels, tipoOptions } from '../../../api/operarios';
import useAuthState, { usePreferencias } from '../../../AuthState';
import Button from '../../common/Button';
import CategoriaOperarioField from '../../common/fields/CategoriaOperarioField';
import { KeyboardDatePicker } from '../../common/fields/KeyboardDatePicker';
import RadioGroupField from './RadioGroupField';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 800,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        activoField: {
            display: 'flex',
            flexDirection: 'column',
        },
        licenciasAgotadas: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            marginTop: -4,
            gap: `${theme.spacing(0.5)}px`,
            '& svg': {
                fontSize: 18,
            },
        },
    }),
    { name: 'EditarDatosGeneralesForm' },
);

export default function EditarDatosGeneralesForm({ tipo, activo, licenciasAgotadas, hidden }) {
    const classes = useStyles();
    const hiddenStyle = hidden ? { display: 'none' } : {};
    const { values } = useFormikContext();

    const { isAdministrativo } = useAuthState();

    const usarNuevaUiOperariosFlag = usePreferencias('usar_nueva_ui_operarios');

    return (
        <Grid container spacing={2} style={hiddenStyle}>
            {!isAdministrativo && (
                <Grid item xs={12}>
                    <RadioGroupField name='tipo' label='Tipo de empleado' options={tipoOptions} />
                </Grid>
            )}
            <Grid item xs={6}>
                <TextField name='codigo' label='Codigo' fullWidth type='number' />
            </Grid>
            <Grid item xs={6}>
                <TextField name='nombre' label='Nombre' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='telefono' label='Telefono' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='email' label='Email' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='nif' label='NIF' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='naf' label='NAF' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <KeyboardDatePicker name='fecha_alta' label='Fecha de alta' format={'dd/MM/yyyy'} fullWidth autoOk />
            </Grid>
            <Grid item xs={6}>
                <KeyboardDatePicker name='fecha_baja' label='Fecha de baja' format={'dd/MM/yyyy'} fullWidth autoOk />
            </Grid>
            {tipo !== ADMINISTRATIVO && (
                <Grid item xs={6}>
                    <CategoriaOperarioField />
                </Grid>
            )}
            {tipo !== ADMINISTRATIVO && !values.categoria_operario && (
                <>
                    <Grid item xs={3}>
                        <TextField
                            name='precio_coste'
                            type='number'
                            step='any'
                            label='Precio coste'
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>€/h</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            name='precio_venta'
                            type='number'
                            step='any'
                            label='Precio venta'
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>€/h</InputAdornment>,
                            }}
                        />
                    </Grid>
                </>
            )}
            <Grid item xs={12} className={classes.activoField}>
                <CheckboxWithLabel
                    type='checkbox'
                    name='activo'
                    Label={{ label: 'Activo' }}
                    disabled={licenciasAgotadas && !activo}
                />
                {licenciasAgotadas && !activo && (
                    <div className={classes.licenciasAgotadas}>
                        <InfoIcon />
                        Has llegado al limite de {tipoLabels[tipo]}. Actualiza tu suscripción o desactiva algún{' '}
                        {tipoLabels[tipo]}.
                        <Button color='primary' to='/mi-cuenta/suscripcion' component={Link}>
                            Actualizar suscripción
                        </Button>
                    </div>
                )}
            </Grid>
            {tipo !== OPERARIO && usarNuevaUiOperariosFlag && (
                <Grid item xs={12}>
                    <CheckboxWithLabel
                        type='checkbox'
                        name='usar_nueva_ui_operarios'
                        Label={{ label: 'Usar nuevo estilo App móvil' }}
                    />
                </Grid>
            )}
        </Grid>
    );
}

EditarDatosGeneralesForm.propTypes = {
    tipo: PropTypes.string.isRequired,
    activo: PropTypes.bool.isRequired,
    hidden: PropTypes.bool.isRequired,
    licenciasAgotadas: PropTypes.bool.isRequired,
};
