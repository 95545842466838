import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import TokenIcon from '@material-ui/icons/ConfirmationNumber';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useCallback, useEffect, useMemo } from 'react';
import Moment from 'react-moment';
import { companiesProvider } from '../../api';
import { estadoLabels, estadoOptions } from '../../api/companies';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate, formatDateTime } from '../../utils';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../common/list/ListView';
import { SelectColumnFilter } from '../common/list/SelectColumnFilter';
import CompaniesKpis from './CompaniesKpis';

const ListView = withTableState('companiesList', BaseListView);

export default function CompaniesList() {
    const snackbar = useSnackbar();
    const fetchDataFn = useCallback(() => companiesProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, []);
    const onDelete = (id) => {
        companiesProvider.delete(id).then(fetchData);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'name',
            },
            {
                Header: 'Fecha de alta',
                accessor: 'registration_date',
                // eslint-disable-next-line react/prop-types
                Cell: ({ cell: { value } }) => <Moment format='DD-MM-YYYY'>{value}</Moment>,
            },
            {
                Header: 'Estado',
                accessor: 'estado',
                Cell: ({ value }) => estadoLabels[value],
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterOptions: {
                    options: estadoOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => estadoLabels[value],
            },
            {
                Header: 'Dias prueba',
                id: 'dias_prueba',
                accessor: (row) => `${row.estado === 'PRUEBA' ? row.dias_restantes_prueba : 0} / ${row.dias_prueba}`,
            },
            {
                Header: 'Fecha fin prueba',
                accessor: 'fecha_fin_prueba',
                Cell: ({ value }) => formatDate(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Activo',
                id: 'activo',
                accessor: (row) => (row.activo ? 'Si' : 'No'),
            },
            {
                Header: 'Kit Digital',
                id: 'kit_digital',
                accessor: (row) =>
                    row.es_kit_digital
                        ? row.fecha_firma_kit_digital
                            ? formatDate(row.fecha_firma_kit_digital)
                            : 'Pendiente'
                        : 'No',
            },
            {
                Header: 'Autoriza pago',
                accessor: 'autoriza_pago',
                Cell: ({ value }) => (value ? 'Si' : 'No'),
            },
            {
                Header: 'Tiene datos de pago',
                id: 'tiene_datos_pago',
                accessor: (row) => (row.datos_pago === null ? 'No' : 'Si'),
            },
            {
                Header: 'Fecha proximo pago',
                id: 'fecha_proximo_pago',
                accessor: (row) => row.tarifa && row.tarifa.fecha_proximo_pago,
                Cell: ({ value }) => (value ? formatDate(value) : 'Sin suscripción'),
            },
            {
                Header: 'Tarifa mensual',
                accessor: 'tarifa.importe',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (value ? `${value.toFixed(2)} EUR` : null),
            },
            {
                Header: 'Recurrencia',
                id: 'recurrencia_pago',
                accessor: (row) => (row.tarifa ? (row.tarifa.pago_mensual ? 'Mensual' : 'Anual') : 'N/D'),
            },
            {
                Header: 'Num operarios (real/tarifa)',
                id: 'numero_operarios',
                accessor: (row) =>
                    `${row.numero_operarios} / ${row.tarifa ? row.tarifa.numero_licencias : 'Sin tarifa'}`,
            },
            {
                Header: 'Num clientes',
                accessor: 'numero_clientes',
            },
            {
                Header: 'Ultimo login admin',
                accessor: 'ultimo_login',
                Cell: ({ value }) => (value ? formatDateTime(value) : 'Sin fecha'),
            },
            {
                Header: 'Ultimo login operario',
                accessor: 'ultimo_login_operario',
                Cell: ({ value }) => (value ? formatDateTime(value) : 'Sin fecha'),
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideDelete: true,
        }),
        [],
    );

    return (
        <>
            <Typography variant='h1'>Compañias</Typography>
            <CompaniesKpis companies={data} />
            <ListView
                tableTitle='Lista de compañias'
                basePath='/companies'
                columns={columns}
                data={data}
                options={options}
                onDelete={onDelete}
                extraActions={(row) => (
                    <Tooltip title='Obtener token'>
                        <IconButton
                            style={{ padding: 6 }}
                            aria-label='Jornada laboral'
                            onClick={() => {
                                companiesProvider
                                    .actionOnId(row.id, 'token', null, { method: 'get' })
                                    .then((res) => snackbar.showMessage(`El token para ${res.name} es "${res.token}"`));
                            }}
                        >
                            <TokenIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                )}
            />
        </>
    );
}
