import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CalendarForm from '../../CalendarForm';
import PropTypes from 'prop-types';

export default function AjustesNoLaborables({ classes }) {
    return (
        <Paper elevation={0}>
            <Typography className={classes.title}>Días no laborables</Typography>
            <CalendarForm />
        </Paper>
    );
}

AjustesNoLaborables.propTypes = {
    classes: PropTypes.any,
};
