import { format as dateFnsFormat } from 'date-fns';
import formatISO from 'date-fns/formatISO';
import es from 'date-fns/locale/es';

export function formatNumber(number, decimals = 2, maxDecimals = null) {
    return number.toLocaleString('es-ES', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: maxDecimals != null ? maxDecimals : decimals,
        useGrouping: true,
    });
}

export function formatCurrency(number, decimals = 2, maxDecimals = null) {
    return `${formatNumber(number, decimals, maxDecimals)} €`;
}

export function format(date, formatStr = 'PP') {
    return dateFnsFormat(new Date(date), formatStr, { locale: es });
}

export function formatDate(date) {
    return format(date, 'dd/MM/yyyy');
}

export function formatFullDate(date) {
    return format(date, 'EEE, dd MMM yyyy');
}

export function formatTime(date) {
    return format(date, 'HH:mm');
}

export function formatDateTime(date) {
    return format(date, 'dd/MM/yyyy HH:mm');
}

export function formatFullDateTime(date) {
    return format(date, 'EEE, dd MMM yyyy HH:mm');
}

export function formatISODate(date) {
    return format(date, 'yyyy-MM-dd');
}

export function formatISODateTime(date) {
    return formatISO(new Date(date));
}

export function formatHora(date) {
    return format(date, 'HH:mm');
}

export function formatTiempo(tiempo, showEmptyMinutes = true, showEmptyHours = true) {
    if (!tiempo) return '0h 00m';

    if (tiempo.minutos === 0 && !showEmptyMinutes) return `${tiempo.horas}h`;

    if (tiempo.horas === 0 && !showEmptyHours) return `${tiempo.minutos}m`;

    const horas = Math.abs(tiempo.horas);
    const minutos = Math.abs(tiempo.minutos);
    const signo = tiempo.horas < 0 || tiempo.minutos < 0 ? '-' : '';

    return `${signo}${horas}h ${minutos.toString().padStart(2, '0')}m`;
}

export function addTiempo(t1, t2) {
    if (!t1) return t2;

    if (!t2) return t1;

    const minutos = t1.minutos + t2.minutos;
    const horas = t1.horas + t2.horas + Math.floor(minutos / 60);

    return {
        horas,
        minutos: minutos % 60,
    };
}

export function isBeginOfDay(d) {
    if (!d) return false;

    const date = new Date(d);
    return date.getHours() === 0 && date.getMinutes() === 0;
}

export function isEndOfDay(d) {
    if (!d) return false;

    const date = new Date(d);
    return date.getHours() === 23 && date.getMinutes() === 59;
}

export function isAllDay(start, end) {
    return isBeginOfDay(start) && isEndOfDay(end);
}

export function isSameTime(start, end) {
    return start.getHours() === end.getHours() && start.getMinutes() === end.getMinutes();
}

export function setBeginOfDay(d) {
    d.setHours(0, 0, 0, 0);
    return d;
}

export function setEndOfDay(d) {
    d.setHours(23, 59, 59, 0);
    return d;
}

export function getInitials(name) {
    // eslint-disable-next-line prefer-regex-literals
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();

    return initials;
}

export function operariosFilterFn(rows, id, filterValue, hideOperariosExtra = false) {
    return rows.filter((row) =>
        row.values[id].some(
            (op) =>
                op.nombre.toLowerCase().includes(filterValue.toLowerCase()) &&
                (!hideOperariosExtra || (hideOperariosExtra && op.visible)),
        ),
    );
}

const noPreparadoEstados = ['PENDIENTE', 'EN_PREPARACION', 'PEDIDO_PROVEEDOR'];
export function estadoPreparacionFilterFn(rows, id, filterValue) {
    return rows.filter((row) =>
        filterValue === 'NO_PREPARADO' ? noPreparadoEstados.includes(row.values[id]) : row.values[id] === filterValue,
    );
}

export function facturadoFilterFn(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return !filterValue || (rowValue !== 'FACTURADO' && rowValue !== 'NO_FACTURABLE');
    });
}

export function deepEqual(x, y) {
    if (x === y) {
        return true;
    } else if (typeof x === 'object' && x != null && typeof y === 'object' && y != null) {
        if (Object.keys(x).length !== Object.keys(y).length) return false;

        for (const prop in x) {
            if (Object.prototype.hasOwnProperty.call(y, prop)) {
                if (!deepEqual(x[prop], y[prop])) return false;
            } else return false;
        }

        return true;
    } else return false;
}
