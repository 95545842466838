import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { companiesProvider } from '../../../../../../api';
import Button from '../../../../../common/Button';
import BaseListView, { withTableState } from '../../../../../common/list/ListView';
import EditTipoHoraDialog from './EditTipoHoraDialog';
import NewTipoHoraDialog from './NewTipoHoraDialog';

const ListView = withTableState('tipos-hora', BaseListView);

export default function AjustesTiposHora({ data, fetchData }) {
    const snackbar = useSnackbar();

    const onDelete = (idx) => {
        companiesProvider
            .deleteCategoriaOperario(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Codigo',
                accessor: 'codigo',
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            deleteConfirmationText: 'borrar tipo hora',
            batchComponents: (
                <NewTipoHoraDialog
                    button={
                        <Button color='transparent' aria-label='Nuevo tipo hora' startIcon={<AddIcon />}>
                            Nuevo tipo de hora
                        </Button>
                    }
                    onSave={() => {
                        fetchData();
                    }}
                />
            ),
        }),
        [],
    );

    return (
        <Paper elevation={0}>
            <ListView
                tableTitle={'Lista de tipos de hora'}
                basePath='/tipos-hora'
                columns={columns}
                data={data}
                options={options}
                onDelete={onDelete}
                extraActions={(row) => (
                    <>
                        {!row.por_defecto ? (
                            <Tooltip title='Marcar por defecto'>
                                <IconButton
                                    onClick={() => {
                                        companiesProvider
                                            .setTipoHoraPorDefecto(row.id)
                                            .then(() => fetchData())
                                            .catch((err) => snackbar.showMessage(err.message));
                                    }}
                                >
                                    <BookmarkBorderIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title='Horario por defecto'>
                                <IconButton>
                                    <BookmarkIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        <EditTipoHoraDialog
                            id={row.id}
                            button={
                                <Tooltip title='Editar tipo de hora'>
                                    <IconButton>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            onSave={fetchData}
                        />
                    </>
                )}
            />
        </Paper>
    );
}

AjustesTiposHora.propTypes = {
    data: PropTypes.any,
    fetchData: PropTypes.any,
};
