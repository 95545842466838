import MuiButton from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';

const Button = withStyles(
    (theme) => {
        const buttonColors = {
            transparent: {
                backgroundColor: 'transparent',
                color: '#1F2A4B',
            },
            normal: {
                backgroundColor: '#E9EAEF',
                color: '#8F95AF',
            },
            success: {
                backgroundColor: '#2CBD96',
                color: 'white',
            },
            error: {
                backgroundColor: theme.palette.error.main,
                color: 'white',
                '&:hover': {
                    backgroundColor: theme.palette.error.main,
                    color: 'white',
                },
                '&:active': {
                    backgroundColor: theme.palette.error.main,
                    color: 'white',
                },
                '&:focus': {
                    backgroundColor: theme.palette.error.main,
                    color: 'white',
                },
                '&.Mui-disabled': {
                    backgroundColor: theme.palette.error.main,
                    color: 'white',
                    opacity: 0.5,
                },
            },
            info: {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                '&:hover': {
                    backgroundColor: 'rgba(49, 72, 254, 1)',
                },
                '&:active': {
                    backgroundColor: 'rgba(49, 72, 254, 1)',
                },
                '&:focus': {
                    backgroundColor: 'rgba(49, 72, 254, 1)',
                },
                '&.Mui-disabled': {
                    backgroundColor: 'rgba(76, 96, 254, 0.1)',
                },
            },
            dark: {
                backgroundColor: theme.palette.neutral.primary,
                color: 'white',
                '&:hover': {
                    backgroundColor: theme.palette.neutral.primary,
                },
                '&:active': {
                    backgroundColor: theme.palette.neutral.primary,
                },
                '&:focus': {
                    backgroundColor: theme.palette.neutral.primary,
                },
                '&.Mui-disabled': {
                    backgroundColor: theme.palette.neutral.primary,
                },
            },
            outlined: {
                backgroundColor: 'transparent',
                color: theme.palette.text.primary,
                border: `1px solid ${theme.palette.neutral.primary}`,
                padding: theme.spacing(2) - 1,
            },
            primary: {
                color: theme.palette.primary.main,
                backgroundColor: 'transparent',
            },
            secondary: {
                color: theme.palette.neutral.primary,
                backgroundColor: 'transparent',
            },
            inherit: {
                color: 'inherit',
                backgroundColor: 'transparent',
            },
        };

        return {
            root: (props) => ({
                boxShadow: 'none',
                textTransform: 'none',
                fontSize: props.size === 'small' ? 12 : 14,
                fontWeight: 500,
                padding: theme.spacing(2),
                lineHeight: 1.5,
                borderRadius: props.radius ?? 4,
                width: props.fullWidth ? '100%' : props.width ?? 'auto',
                height: props.size === 'small' ? 32 : props.size === 'big' ? 44 : 32,
                '&:hover': {
                    backgroundColor: buttonColors[props.color || 'normal'].background,
                },
                '&:active': {
                    backgroundColor: buttonColors[props.color || 'normal'].background,
                },
                '&:focus': {
                    backgroundColor: buttonColors[props.color || 'normal'].background,
                },
                '& .MuiSvgIcon-root': {
                    fontSize: 20,
                },
                ...buttonColors[props.color],
            }),
        };
    },
    { name: 'Button' },
)(({ color, radius, ...props }) => React.createElement(MuiButton, props));

export default Button;
