import { formatISODateTime } from '../utils';
import DataProvider from './DataProvider';
import { createTiempo } from './tareas-functions';

export const empty = {
    codigo: '',
    nombre: '',
    email: '',
    telefono: '',
    nif: '',
    naf: '',
    user: null,
    activo: true,
    is_admin: false,
    horarios: null,
    categoria_operario_id: null,
    tipo: '',
};

export const OPERARIO = 'OPERARIO';
export const ADMINISTRATIVO = 'ADMINISTRATIVO';
export const OPERARIO_ADMINISTRATIVO = 'OPERARIO_ADMINISTRATIVO';

export const tipoLabels = {
    [OPERARIO]: 'Operario',
    [ADMINISTRATIVO]: 'Administrativo',
    [OPERARIO_ADMINISTRATIVO]: 'Operario-Administrativo',
};

export const tipoOptions = Object.entries(tipoLabels).map(([value, label]) => ({ value, label }));

export const INCLUDE_ADMINISTRATIVOS = true;

function getDefaultPermisosOperario() {
    return {
        crear_servicios: true,
        editar_servicios: true,
        cerrar_servicios: true,
        generar_albaranes: true,
        enviar_albaranes: true,
        responsable_operarios: true,
        responsable_proyectos: true,
        recibir_copia_albaranes_propios: false,
        fichar: true,
        marcar_entrada_salida: null,
    };
}

function getDefaultPermisosAdministrativo() {
    return {
        gestionar_clientes: true,
        gestionar_proveedores: true,
        gestionar_empleados: true,
        gestionar_mantenimientos: true,
        fichar: true,
        gestionar_fichajes: true,
        gestionar_preparaciones_material: true,
        gestionar_vacaciones_ausencias: true,
        gestionar_documentacion: true,
    };
}

export function getDefaultPermisos(tipo) {
    if (tipo === OPERARIO) return getDefaultPermisosOperario();
    if (tipo === ADMINISTRATIVO) return getDefaultPermisosAdministrativo();
    if (tipo === OPERARIO_ADMINISTRATIVO)
        return { ...getDefaultPermisosOperario(), ...getDefaultPermisosAdministrativo() };

    return {};
}

class OperariosProvider extends DataProvider {
    constructor() {
        super('operarios', empty);
    }

    getAllAsOptions = async () => this.getAll('as_options?includeAll');
    getOnlyActiveAsOptions = async (includeAdministrativos = false) =>
        this.getAll(`as_options${includeAdministrativos ? '?includeAdministrativos' : ''}`);

    getPlanificacionOptions = async (fechaInicio, fechaFin, planificacionId) => {
        return this.getAll(
            `planificacion_options?planificacion_id=${planificacionId ?? ''}&fecha_inicio=${encodeURIComponent(
                formatISODateTime(fechaInicio),
            )}&fecha_fin=${encodeURIComponent(formatISODateTime(fechaFin))}`,
        );
    };

    getHorarios = async (id) => {
        return this.actionOnId(id, 'horarios', null, { method: 'get' });
    };

    getResumenHorasMes = async (id, date) => {
        return this.actionOnId(id, `resumen-horas/${date.getFullYear()}/${date.getMonth() + 1}`, null, {
            method: 'get',
            // eslint-disable-next-line camelcase
        }).then(({ horas_laborables, minutos_trabajados, minutos_trabajados_reales, minutos_extras }) => ({
            laborables: horas_laborables,
            trabajadas: createTiempo(minutos_trabajados),
            reales: createTiempo(minutos_trabajados_reales),
            extras: createTiempo(minutos_extras),
            // eslint-disable-next-line camelcase
            diferencia: createTiempo(minutos_trabajados_reales - minutos_trabajados, true),
        }));
    };

    getDiasVacaciones = async (id) => {
        return this.actionOnId(id, 'dias_vacaciones', null, { method: 'get' }).then((diasVacaciones) => {
            const thisYear = new Date().getFullYear();
            const nextYear = thisYear + 1;

            if (!diasVacaciones.some(({ year }) => year === thisYear)) {
                diasVacaciones.push({ year: thisYear, dias: 0 });
            }
            if (!diasVacaciones.some(({ year }) => year === nextYear)) {
                diasVacaciones.push({ year: nextYear, dias: 0 });
            }
            return diasVacaciones;
        });
    };

    addHorarios = async (id, fecha, params) => {
        return this.actionOnId(id, 'horarios', {
            fecha: fecha ?? new Date(),
            ...params,
        });
    };

    deleteHorarios = async (id, idx) => {
        return this.actionOnId(id, `horarios/${idx}`, null, { method: 'delete' });
    };

    getControlJornadaOperario = async function (operarioId, date) {
        if (operarioId === null) return [];

        return this.getAll(`${operarioId}/control-jornada/${date.getFullYear()}/${date.getMonth() + 1}`);
    };

    getControlJornadaDia = async function (date) {
        return this.getAll(`control-jornada/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`);
    };
}

export const getMarcajesFromHorarios = (horarios, fecha) => {
    return horarios.map((horario) => {
        const entrada = new Date(fecha);
        entrada.setHours(horario.hora_entrada, horario.minutos_entrada);
        const salida = new Date(fecha);
        salida.setHours(horario.hora_salida, horario.minutos_salida);

        return {
            hora_entrada: entrada,
            hora_salida: salida,
        };
    });
};

export const getHorariosFromMarcajes = (marcajes) => {
    // eslint-disable-next-line camelcase
    return marcajes.map(({ hora_entrada, hora_salida }) => {
        return {
            hora_entrada: hora_entrada.getHours(),
            minutos_entrada: hora_entrada.getMinutes(),
            hora_salida: hora_salida.getHours(),
            minutos_salida: hora_salida.getMinutes(),
        };
    });
};

export const getMinutosFromHorarios = (horarios) => {
    return horarios
        .map((horario) => {
            const entrada = horario.hora_entrada * 60 + horario.minutos_entrada;
            const salida = horario.hora_salida * 60 + horario.minutos_salida;

            return salida - entrada;
        })
        .reduce((a, b) => a + b, 0);
};

export const dataProvider = new OperariosProvider();
