import { Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import isAfter from 'date-fns/isAfter';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { albaranesCompraProvider, tareasProvider } from '../../../api';
import { gastosLabels } from '../../../api/tareas';
import { isEqualTiempo } from '../../../api/tareas-functions';
import useAuthState from '../../../AuthState';
import { formatDate, formatFullDate, formatFullDateTime, formatTiempo } from '../../../utils';
import { openSearchInMaps } from '../../clientes/direcciones/NewDireccionDialog';
import Button from '../../common/Button';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsHeader from '../../common/forms/MasterDetailsView/MasterDetailsHeader';
import PaperTextEditor from '../../common/forms/PaperTextEditor';
import { ToggleButton, ToggleButtonGroup } from '../../common/ToggleButtonGroup';
import AlbaranesCompraInfoTooltip from '../AlbaranesCompraInfoTooltip';
import Observaciones from '../NotasDialog/Observaciones';
import GastoEditor from './GastoEditor';
import ManoObraEditor from './ManoObraEditor';
import MarcajesDialog from './MarcajesDialog/MarcajesDialog';
import MaterialEditor from './MaterialEditor';

const Table = withStyles(
    (theme) => ({
        root: {
            borderCollapse: 'separate',
            borderSpacing: '16px',
        },
    }),
    { name: 'Table' },
)(MuiTable);

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: 0,
            borderBottom: '1px solid #ccc',
            '&:not(:first-of-type)': {
                whiteSpace: 'nowrap',
            },
            '.MuiTableHead-root &': {
                border: 'none',
            },
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        sizeSmall: {
            '&:last-child': {
                padding: 0,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const DenseTable = withStyles(
    (theme) => ({
        root: {
            margin: theme.spacing(1, 0),
        },
    }),
    { name: 'DenseTable' },
)(MuiTable);

const DenseTableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0, 2),
        },
    }),
    { name: 'DenseTableCell' },
)(MuiTableCell);

const useStyles = makeStyles(
    (theme) => ({
        wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: `${theme.spacing(2)}px`,
        },
        paper: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        fullWidth: {
            width: '100%',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            padding: theme.spacing(0, 2),
        },
        dividerRow: {
            '& td': {
                padding: 0,
                border: 'none',
            },
        },
        emptyRow: {
            '& td': {
                color: theme.palette.neutral.primary,
            },
            '& td:not(:first-of-type)': {
                border: 'none',
            },
        },
        actions: {
            whiteSpace: 'nowrap',
            textAlign: 'right',
            border: 'none',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        descripcion: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    }),
    { name: 'ParteTrabajoDetails' },
);

export const COSTE = 'coste';
export const VENTA = 'venta';

export const precioViews = [
    {
        id: COSTE,
        label: 'Coste',
    },
    {
        id: VENTA,
        label: 'Venta',
    },
];

export function useSubtareasList(selectedJornadaDate) {
    const { data: checklists } = useEditFormData('checklists_tarea');

    return useMemo(() => {
        if (!checklists) return [];

        const checklist =
            [...checklists].reverse().find((c) => !isAfter(new Date(c.fecha_inicio), new Date(selectedJornadaDate))) ||
            checklists[0];

        if (!checklist || !checklist.contar_tiempo) return [];

        return checklist.items
            .filter((item) => Boolean(item.subtarea_id))
            .map((item) => ({ id: item.subtarea_id, codigo: item.subtarea?.codigo, titulo: item.texto }));
    }, [selectedJornadaDate, checklists]);
}

export default function ParteTrabajoDetails({ index, preciosGastos, onUpdate, size = 'big' }) {
    const classes = useStyles();
    const history = useHistory();
    const {
        data: jornada,
        updateLocalData: updateLocalDataOriginal,
        fetchData: fetchDataOriginal,
    } = useEditFormData(`jornadas.${index}`);
    const { data: albaranes } = useEditFormData('albaranes');
    const [currentView, setCurrentView] = useState(VENTA);
    const [albaranesCompraMap, setAlbaranesCompraMap] = useState({
        map: {},
        albaranes: {},
    });

    const subtareas = useSubtareasList(jornada.fecha);

    function updateLocalData(...args) {
        updateLocalDataOriginal(...args);
        onUpdate && onUpdate();
    }

    function fetchData(...args) {
        fetchDataOriginal(...args);
        onUpdate && onUpdate();
    }

    const tareaId = jornada.tarea_id;

    useEffect(() => {
        const missingAlbaranCompraLineaIds = jornada.materiales
            .map((m) => m.albaran_compra_linea_id)
            .filter(Boolean)
            .filter((id) => !albaranesCompraMap.map[id]);

        if (missingAlbaranCompraLineaIds.length > 0) {
            albaranesCompraProvider
                .getAlbaranesCompraByLineas(missingAlbaranCompraLineaIds)
                .then(setAlbaranesCompraMap);
        }
    }, []);

    const {
        userInfo: { preferencias: { usar_precios: usarPrecios, usar_incidencias: usarIncidencias } = {} },
    } = useAuthState();

    const albaran = albaranes.find((albaran) => albaran.id === jornada.albaran_id);
    const albaranEnviado = albaran && albaran.fecha_envio !== null;

    const [editTimeOpen, setEditTimeOpen] = React.useState(false);
    const openTimeEditor = () => {
        setEditTimeOpen(true);
    };
    const closeTimeEditor = () => {
        setEditTimeOpen(false);
    };

    const [editMaterialInfo, setEditMaterialInfo] = React.useState(null);
    const openMaterialEditor =
        (jornadaId, { id, ...material } = { id: null, descripcion: '', unidades: '' }) =>
        (event) => {
            setEditMaterialInfo({
                jornadaId,
                materialId: id,
                material,
            });
        };
    const closeMaterialEditor = () => {
        setEditMaterialInfo(null);
    };

    const [editGastoInfo, setEditGastoInfo] = React.useState(null);
    const openGastoEditor = (jornadaId, key, gasto) => (event) => {
        setEditGastoInfo({
            jornadaId,
            key,
            unidades: gasto.unidades,
            precioCoste: gasto.precioCoste,
            precioVenta: gasto.precioVenta,
            descuento: gasto.descuento,
        });
    };
    const closeGastoEditor = () => {
        setEditGastoInfo(null);
    };

    const totalMaterialVenta = jornada.materiales
        .filter((m) => m.importe !== null)
        .reduce((total, material) => total + material.importe, 0);

    const totalMaterialCoste = jornada.materiales
        .filter((m) => m.importe_coste !== null)
        .reduce((total, material) => total + material.importe_coste, 0);

    const marcajesNotEqual = !isEqualTiempo(jornada.tiempo, jornada.tiempo_marcajes);
    const marcajesAbiertos = jornada.marcajes.some((m) => m.hora_entrada !== null && m.hora_salida === null);

    let marcajesWarningText = null;
    if (marcajesAbiertos) marcajesWarningText = 'Hay marcajes abiertos';
    else if (marcajesNotEqual) marcajesWarningText = 'Los marcajes no coinciden con las horas facturables';

    const observacion =
        jornada.notas.length > 0
            ? {
                  jornadaId: jornada.id,
                  fecha: jornada.fecha,
                  operario: jornada.operario,
                  texto: '',
                  vista: true,
                  fecha_actualizacion: null,
                  ...jornada.notas[0],
                  fotos: jornada.fotos,
                  incidencia: usarIncidencias ? jornada.notas[0].incidencia : false,
              }
            : null;

    const updateGpsLocation = jornada.last_updated_location?.split(',').slice(0, 2).join(',');

    return (
        <div className={classes.wrapper}>
            <MasterDetailsHeader
                title='Parte de trabajo de:'
                subtitle={jornada.operario}
                items={[
                    { label: 'Fecha', value: formatFullDate(jornada.fecha) },
                    {
                        label: 'Última actualización',
                        value: (
                            <>
                                {formatFullDateTime(jornada.fecha_actualizacion)}
                                {updateGpsLocation ? (
                                    <Tooltip arrow title='Abrir en Google Maps'>
                                        <IconButton size='small' onClck={() => openSearchInMaps(updateGpsLocation)}>
                                            <LocationOnIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    ''
                                )}
                            </>
                        ),
                    },
                ]}
            />
            {jornada.operario && (
                <PaperTextEditor
                    title='Descripción del trabajo realizado'
                    value={jornada.explicacion}
                    className={clsx(classes.paper, size === 'small' ? classes.fullWidth : null)}
                    placeholder='Escribe la descripción de lo que ha hecho el operario en este parte de trabajo'
                    onSave={(value) => {
                        tareasProvider
                            .saveJornadaExplicacion(jornada.id, value)
                            .then(() => updateLocalData('explicacion', value));
                    }}
                />
            )}
            {observacion && (
                <Paper elevation={0} className={clsx(classes.paper, size === 'small' ? classes.fullWidth : null)}>
                    <Typography variant='body1' className={classes.title}>
                        {observacion.incidencia ? 'Incidencia' : 'Observaciones y fotos'}
                    </Typography>
                    <Observaciones
                        observacion={observacion}
                        onRead={() => {
                            tareasProvider.updateJornadaNotaVista(observacion.jornadaId).then((jornadas) => {
                                updateLocalData(null, {
                                    ...jornada,
                                    ...jornadas.find((j) => j.id === observacion.jornadaId),
                                });
                            });
                        }}
                        onDelete={() => {
                            tareasProvider.deleteJornadaNotas(observacion.jornadaId).then((jornadas) => {
                                updateLocalData(null, {
                                    ...jornada,
                                    notas: [],
                                    fotos: [],
                                });
                            });
                        }}
                        onUpdateResolucion={(resolucion) => {
                            tareasProvider
                                .updateJornadaNotaIncidenciaResolucion(observacion.jornadaId, resolucion)
                                .then((jornadas) => {
                                    updateLocalData(null, {
                                        ...jornada,
                                        ...jornadas.find((j) => j.id === observacion.jornadaId),
                                    });
                                });
                        }}
                        onResolver={(resolver) => {
                            tareasProvider
                                .updateJornadaNotaIncidenciaResuelta(observacion.jornadaId, resolver)
                                .then((jornadas) => {
                                    updateLocalData(null, {
                                        ...jornada,
                                        ...jornadas.find((j) => j.id === observacion.jornadaId),
                                    });
                                });
                        }}
                        onDeleteFoto={(fotoId) => {
                            tareasProvider.deleteFoto(fotoId).then(({ fotos }) => {
                                updateLocalData('fotos', fotos);
                            });
                        }}
                    />
                </Paper>
            )}
            {jornada.operario === null && (
                <Paper elevation={0} className={clsx(classes.paper, classes.fullWidth)}>
                    <Typography variant='body1' className={classes.title}>
                        Albaranes de compra imputados
                    </Typography>

                    <DenseTable size='small' style={{ marginTop: 8 }}>
                        <TableHead>
                            <TableRow>
                                <DenseTableCell>Referencia</DenseTableCell>
                                <DenseTableCell>Núm. Fra.</DenseTableCell>
                                <DenseTableCell>Proveedor</DenseTableCell>
                                <DenseTableCell>Fecha pedido</DenseTableCell>
                                <DenseTableCell>Fecha llegada</DenseTableCell>
                                <DenseTableCell>Acciones</DenseTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(albaranesCompraMap.albaranes).map((albaranCompra, index) => (
                                <TableRow key={index}>
                                    <DenseTableCell>{albaranCompra.referencia}</DenseTableCell>
                                    <DenseTableCell>{albaranCompra.factura_compra}</DenseTableCell>
                                    <DenseTableCell>{albaranCompra.proveedor.nombre}</DenseTableCell>
                                    <DenseTableCell>{formatDate(albaranCompra.fecha_solicitud)}</DenseTableCell>
                                    <DenseTableCell>
                                        {albaranCompra.fecha_llegada
                                            ? formatDate(albaranCompra.fecha_llegada)
                                            : 'Sin fecha'}
                                    </DenseTableCell>
                                    <DenseTableCell className={classes.actions}>
                                        <Tooltip title='Ver albarán de compra'>
                                            <IconButton
                                                onClick={() => history.push(`/albaranes-compra/${albaranCompra.id}`)}
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </DenseTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </DenseTable>
                </Paper>
            )}
            {albaranEnviado && (
                <Alert severity='warning' className={classes.fullWidth}>
                    Este parte de trabajo ya está enviado. Si lo editas, recuerda reenviarlo al cliente.
                </Alert>
            )}
            <Paper elevation={0} className={clsx(classes.paper, classes.fullWidth)}>
                <Typography variant='body1' component='div' className={classes.title}>
                    {jornada.operario ? 'Detalles del parte de trabajo' : 'Lista de material'}

                    {usarPrecios && (
                        <ToggleButtonGroup value={currentView} exclusive onChange={(ev, view) => setCurrentView(view)}>
                            {precioViews.map(({ id, label }) => (
                                <ToggleButton key={id} value={id}>
                                    {label}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    )}
                </Typography>

                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '100%' }}>Descripción</TableCell>
                            <TableCell>Unidades</TableCell>
                            {usarPrecios && <TableCell>Precio</TableCell>}
                            {usarPrecios && currentView === VENTA && <TableCell>Dto</TableCell>}
                            {usarPrecios && <TableCell>Importe</TableCell>}
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jornada.operario && (
                            <>
                                <TableRow>
                                    <TableCell>Mano de obra</TableCell>
                                    <TableCell align='right'>
                                        <MarcajesDialog
                                            button={
                                                <Tooltip arrow title={marcajesWarningText ?? 'Ver marcajes'}>
                                                    <IconButton>
                                                        <ScheduleIcon
                                                            color={marcajesWarningText ? 'error' : 'inherit'}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            tareaId={tareaId}
                                            jornada={jornada}
                                            onSave={fetchData}
                                            subtareas={subtareas}
                                        />
                                        {formatTiempo(jornada.tiempo)}
                                    </TableCell>
                                    {usarPrecios && (
                                        <>
                                            <TableCell align='right'>
                                                {jornada[
                                                    currentView === VENTA ? 'precio_unitario' : 'precio_unitario_coste'
                                                ].toFixed(2)}
                                                €
                                            </TableCell>
                                            {currentView === VENTA && (
                                                <TableCell align='right'>{jornada.descuento.toFixed(0)}%</TableCell>
                                            )}
                                            <TableCell align='right'>
                                                {jornada[currentView === VENTA ? 'importe' : 'importe_coste'].toFixed(
                                                    2,
                                                )}
                                                €
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell className={classes.actions}>
                                        <IconButton onClick={openTimeEditor}>
                                            <EditIcon />
                                        </IconButton>
                                        <DeleteDialog
                                            text={
                                                'Si continuas, se establecerá la mano de obra a 0h 0min.\n' +
                                                '¿Estás seguro que quieres continuar?'
                                            }
                                            onConfirm={() =>
                                                tareasProvider
                                                    .updateTiempoJornada(jornada.id, { horas: 0, minutos: 0 })
                                                    .then(fetchData)
                                            }
                                            button={
                                                <IconButton>
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow className={classes.dividerRow} />
                            </>
                        )}
                        {jornada.materiales.length === 0 && (
                            <TableRow className={classes.emptyRow}>
                                <TableCell>No hay materiales</TableCell>
                                <TableCell colSpan={2} />
                            </TableRow>
                        )}
                        {jornada.materiales.map((material, i) => {
                            const albaranCompraId = albaranesCompraMap.map[material.albaran_compra_linea_id];
                            return (
                                <TableRow key={i}>
                                    <TableCell>
                                        <div className={classes.descripcion}>
                                            {material.descripcion}{' '}
                                            {albaranCompraId ? (
                                                <AlbaranesCompraInfoTooltip
                                                    albaranes={[albaranesCompraMap.albaranes[albaranCompraId]]}
                                                />
                                            ) : null}
                                        </div>
                                    </TableCell>
                                    <TableCell align='right'>{material.unidades}</TableCell>
                                    {usarPrecios && (
                                        <>
                                            <TableCell align='right'>
                                                {material[
                                                    currentView === VENTA ? 'precio_unitario' : 'precio_unitario_coste'
                                                ].toFixed(2)}
                                                €
                                            </TableCell>
                                            {currentView === VENTA && (
                                                <TableCell align='right'>{material.descuento.toFixed(0)}%</TableCell>
                                            )}
                                            <TableCell align='right'>
                                                {material[currentView === VENTA ? 'importe' : 'importe_coste'] !== null
                                                    ? `${material[
                                                          currentView === VENTA ? 'importe' : 'importe_coste'
                                                      ].toFixed(2)}€`
                                                    : null}
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell className={classes.actions}>
                                        <IconButton onClick={openMaterialEditor(jornada.id, material)}>
                                            <EditIcon />
                                        </IconButton>
                                        <DeleteDialog
                                            text='¿Estás seguro que quieres eliminar este material?'
                                            onConfirm={() =>
                                                tareasProvider
                                                    .deleteMaterialJornada(jornada.id, material.id)
                                                    .then((jornada) => updateLocalData(null, jornada))
                                            }
                                            button={
                                                <IconButton>
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {usarPrecios && (
                            <TableRow>
                                <TableCell colSpan={currentView === VENTA ? 4 : 3}>Total material</TableCell>
                                <TableCell align='right'>
                                    {(currentView === VENTA ? totalMaterialVenta : totalMaterialCoste).toFixed(2)}€
                                </TableCell>
                                <TableCell className={classes.actions}></TableCell>
                            </TableRow>
                        )}

                        {jornada.operario && (
                            <>
                                <TableRow className={classes.dividerRow}>
                                    <TableCell colSpan={3}>
                                        <Button
                                            color='primary'
                                            startIcon={<AddIcon />}
                                            size='small'
                                            style={{ paddingLeft: 0 }}
                                            onClick={openMaterialEditor(jornada.id)}
                                        >
                                            Añadir material
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow className={classes.dividerRow} />
                                {Object.entries(gastosLabels).map(([key, label]) => {
                                    const gasto = {
                                        unidades: 0,
                                        precioCoste: preciosGastos[`precio_coste_${key}`],
                                        precioVenta: preciosGastos[`precio_venta_${key}`],
                                        descuento: 0,
                                        importeVenta: null,
                                        importeCoste: null,
                                    };

                                    if (jornada.gastos) {
                                        gasto.unidades = Number(jornada.gastos[key]);
                                        gasto.precioCoste = Number(jornada.gastos[`precio_coste_${key}`]);
                                        gasto.precioVenta = Number(jornada.gastos[`precio_venta_${key}`]);
                                        gasto.descuento = Number(jornada.gastos[`descuento_${key}`]);
                                        gasto.importeVenta = Number(jornada.gastos[`importe_${key}`]);
                                        gasto.importeCoste = Number(jornada.gastos[`importe_coste_${key}`]);
                                    }

                                    return (
                                        <TableRow key={key}>
                                            <TableCell>{label}</TableCell>
                                            <TableCell align='right'>{gasto.unidades.toFixed(2)}</TableCell>
                                            {usarPrecios &&
                                                (jornada.gastos ? (
                                                    <>
                                                        <TableCell align='right'>
                                                            {gasto[
                                                                currentView === VENTA ? 'precioVenta' : 'precioCoste'
                                                            ] != null
                                                                ? `${gasto[
                                                                      currentView === VENTA
                                                                          ? 'precioVenta'
                                                                          : 'precioCoste'
                                                                  ].toFixed(2)}€`
                                                                : null}
                                                        </TableCell>
                                                        {currentView === VENTA && (
                                                            <TableCell align='right'>
                                                                {gasto.descuento != null
                                                                    ? `${gasto.descuento.toFixed(0)}%`
                                                                    : null}
                                                            </TableCell>
                                                        )}
                                                        <TableCell align='right'>
                                                            {gasto[
                                                                currentView === VENTA ? 'importeVenta' : 'importeCoste'
                                                            ] !== null
                                                                ? `${gasto[
                                                                      currentView === VENTA
                                                                          ? 'importeVenta'
                                                                          : 'importeCoste'
                                                                  ].toFixed(2)}€`
                                                                : null}
                                                        </TableCell>
                                                    </>
                                                ) : (
                                                    <TableCell colSpan={currentView === VENTA ? 3 : 2} align='center'>
                                                        No hay gastos registrados
                                                    </TableCell>
                                                ))}
                                            <TableCell className={classes.actions}>
                                                <IconButton onClick={openGastoEditor(jornada.id, key, gasto)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <DeleteDialog
                                                    text={
                                                        `Si continuas, se establecerá el gasto de ${label.toLowerCase()} a 0.\n` +
                                                        '¿Estás seguro que quieres continuar?'
                                                    }
                                                    onConfirm={() =>
                                                        tareasProvider
                                                            .getAll(`jornadas/${jornada.id}/gastos`, {
                                                                method: 'post',
                                                                body: JSON.stringify({
                                                                    ...jornada.gastos,
                                                                    [key]: 0,
                                                                }),
                                                            })
                                                            .then((newGastos) => {
                                                                updateLocalData('gastos', newGastos);
                                                            })
                                                    }
                                                    button={
                                                        <IconButton>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </>
                        )}
                    </TableBody>
                </Table>
            </Paper>
            <ManoObraEditor
                open={editTimeOpen}
                jornada={jornada}
                onClose={closeTimeEditor}
                onSave={({
                    tiempo,
                    precio_unitario_coste: precioCoste,
                    precio_unitario: precioVenta,
                    descuento,
                    categoria_operario_id: categoriaOperarioId,
                    tipo_hora_id: tipoHoraId,
                }) => {
                    tareasProvider
                        .updateTiempoJornada(
                            jornada.id,
                            tiempo,
                            precioCoste,
                            precioVenta,
                            descuento,
                            categoriaOperarioId,
                            tipoHoraId,
                        )
                        .then((updatedTarea) => {
                            updateLocalData(
                                null,
                                updatedTarea.jornadas.find((j) => j.id === jornada.id),
                            );
                            closeTimeEditor();
                        });
                }}
            />
            <MaterialEditor
                open={Boolean(editMaterialInfo)}
                {...editMaterialInfo}
                onClose={closeMaterialEditor}
                onSave={(material) => {
                    const { jornadaId, materialId } = editMaterialInfo;

                    let idx = jornada.materiales.findIndex((material) => material.id === materialId);
                    if (idx < 0) idx = jornada.materiales.length;

                    return tareasProvider
                        .updateMaterialJornada(jornadaId, materialId, material)
                        .then((updatedMaterial) => {
                            updateLocalData(`materiales.${idx}`, updatedMaterial);
                            closeMaterialEditor();
                        });
                }}
            />
            <GastoEditor
                id={jornada.id}
                open={Boolean(editGastoInfo)}
                {...editGastoInfo}
                onClose={closeGastoEditor}
                onSave={(unidades, precioCoste, precioVenta, descuento) => {
                    const values = {
                        ...jornada.gastos,
                        [editGastoInfo.key]: unidades,
                    };
                    if (usarPrecios) {
                        values[`precio_coste_${editGastoInfo.key}`] = precioCoste;
                        values[`precio_venta_${editGastoInfo.key}`] = precioVenta;
                        values[`descuento_${editGastoInfo.key}`] = descuento;
                    }
                    return tareasProvider
                        .getAll(`jornadas/${jornada.id}/gastos`, {
                            method: 'post',
                            body: JSON.stringify(values),
                        })
                        .then((newGastos) => {
                            updateLocalData('gastos', newGastos);
                            closeGastoEditor();
                        });
                }}
            />
        </div>
    );
}

ParteTrabajoDetails.propTypes = {
    index: PropTypes.any.isRequired,
    preciosGastos: PropTypes.any,
    onUpdate: PropTypes.func,
    size: PropTypes.oneOf(['big', 'small']),
};
