import Collapse from '@material-ui/core/Collapse';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import { createContext, useContext, useEffect, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import useAuthState from './AuthState';
import Agenda from './components/agenda/Agenda';
import AlbaranesCompraModule from './components/albaranes_compra/AlbaranesCompraModule';
import CalendarioPlanificacion from './components/calendario_planificacion/CalendarioPlanificacion';
import ClientesModule from './components/clientes/ClientesModule';
import CompaniesModule from './components/companies/CompaniesModule';
import ProfileForm from './components/companies/profile/EditProfileForm';
import ContratosMantenimientoModule from './components/contratos_mantenimiento/ContratosMantenimientoModule';
import ControlJornada from './components/control_jornada/ControlJornada';
import CuestionariosModule from './components/cuestionarios/CuestionariosModule';
import Dashboard from './components/dashboard/Dashboard';
import DocumentosModule from './components/documentos/DocumentosModule';
import EquiposModule from './components/equipos/EquiposModule';
import Facturacion from './components/facturacion/Facturacion';
import FichajesModule from './components/fichajes/FichajesModule';
import InstalacionesModule from './components/instalaciones/InstalacionesModule';
import AppBar from './components/layout/AppBar';
import MaterialesModule from './components/materiales/MaterialesModule';
import OperariosModule from './components/operarios/OperariosModule';
import PreparacionesMaterial from './components/preparaciones_material/PreparacionesMaterial';
import PresupuestosModule from './components/presupuestos/PresupuestosModule';
import ProveedoresModule from './components/proveedores/ProveedoresModule';
import ProyectosModule from './components/proyectos/ProyectosModule';
import RendimientoServiciosModule from './components/rendimiento_servicios/RendimientoServiciosModule';
import RevisionMaterial from './components/revision_material/RevisionMaterial';
import RevisionIncidencias from './components/revision_partes/RevisionIncidencias';
import RevisionPartes from './components/revision_partes/RevisionPartes';
import ServiciosModule from './components/servicios/ServiciosModule';
import SolicitudesModule from './components/solicitudes/SolicitudesModule';
import VehiculosModule from './components/vehiculos/VehiculosModule';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
        },
        appBarSpacer: {
            ...theme.mixins.toolbar,
            '@media print': {
                display: 'none',
            },
        },
        content: {
            height: '100vh',
            overflow: 'auto',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            scrollbarGutter: 'stable',
        },
        container: {
            position: 'relative',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        containerGutters: {
            margin: theme.spacing(11, 3, 3, 3),
        },
        containerNoGutters: {
            margin: theme.spacing(8, 0, 0, 0),
            overflow: 'hidden',
        },
    }),
    { name: 'MainApp' },
);

function HelpscoutBeacon() {
    useEffect(() => {
        window.Beacon('init', '5d0b2067-30e8-49bb-8d57-fba0f22bfdec');
    }, []);

    return null;
}

const MainAppContext = createContext();

export const GUTTERS = 'gutters';
export const NO_GUTTERS = 'no-gutters';

export function useMainAppContext() {
    return useContext(MainAppContext);
}

export default function MainApp() {
    const classes = useStyles();
    const { isAdmin, isCompany, testInfo } = useAuthState();

    const [containerClass, setContainerClass] = useState(classes.containerGutters);

    const gutterTypeClasses = {
        [GUTTERS]: classes.containerGutters,
        [NO_GUTTERS]: classes.containerNoGutters,
    };
    function setContainerGutters(gutterType) {
        setContainerClass(gutterTypeClasses[gutterType]);
    }

    // const getTimeAndReset = useActiveInteraction();
    // const location = useLocation();
    //
    // useEffect(() => {
    //     // Log page visit and interaction time
    //     return () => {
    //         console.log('Visited page:', location.pathname, 'during', getTimeAndReset(), 'seconds');
    //     };
    // }, [location]);

    return (
        <div className={classes.root}>
            <HelpscoutBeacon />
            <CssBaseline />
            <AppBar />
            <MainAppContext.Provider value={{ setContainerGutters }}>
                {/* <div className={classes.appBarSpacer} /> */}
                <div className={clsx(classes.container, containerClass)}>
                    <Collapse in={!testInfo.isMessageDismissed}>
                        <Alert
                            variant='filled'
                            severity={testInfo.daysRemaining < 2 ? 'warning' : 'info'}
                            style={{ marginBottom: 16 }}
                            action={
                                <IconButton
                                    aria-label='close'
                                    color='inherit'
                                    size='small'
                                    onClick={testInfo.dismissMessage}
                                >
                                    <CloseIcon fontSize='inherit' />
                                </IconButton>
                            }
                        >
                            {testInfo.daysRemaining <= 0
                                ? 'Tu periodo de prueba gratis ha finalizado'
                                : `${testInfo.daysRemaining <= 3 ? '¡Atención! Solo' : '¡Hola! Aun'} te quedan ${
                                      testInfo.daysRemaining
                                  } dias de prueba`}
                            .
                            <Link
                                style={{ color: 'white', fontWeight: 'bold', marginLeft: 4, marginRight: 4 }}
                                to={'/mi-cuenta/suscripcion'}
                            >
                                Configura tu suscripción
                            </Link>
                            {testInfo.daysRemaining <= 0
                                ? 'para seguir utilizando Labory'
                                : 'antes de que termine el periodo de prueba'}
                            .
                        </Alert>
                    </Collapse>
                    <Switch>
                        <Route exact path='/'>
                            <Dashboard />
                        </Route>
                        {isAdmin && (
                            <Route path='/companies'>
                                <CompaniesModule />
                            </Route>
                        )}
                        {isCompany && (
                            <>
                                <Route path='/empleados'>
                                    <OperariosModule />
                                </Route>
                                <Route path='/fichajes'>
                                    <FichajesModule />
                                </Route>
                                <Route path='/solicitudes'>
                                    <SolicitudesModule />
                                </Route>
                                <Route path='/materiales'>
                                    <MaterialesModule />
                                </Route>
                                <Route path='/calendario-planificacion'>
                                    <CalendarioPlanificacion />
                                </Route>
                                <Route path='/calendario'>
                                    <Agenda />
                                </Route>
                                <Route path='/servicios'>
                                    <ServiciosModule />
                                </Route>
                                <Route path='/cuestionarios'>
                                    <CuestionariosModule />
                                </Route>
                                <Route path='/equipos'>
                                    <EquiposModule />
                                </Route>
                                <Route path='/proyectos'>
                                    <ProyectosModule />
                                </Route>
                                <Route path='/presupuestos'>
                                    <PresupuestosModule />
                                </Route>
                                <Route path='/instalaciones'>
                                    <InstalacionesModule />
                                </Route>
                                <Route path='/contratos-mantenimiento'>
                                    <ContratosMantenimientoModule />
                                </Route>
                                <Route path='/facturacion'>
                                    <Facturacion />
                                </Route>
                                <Route path='/rendimiento-servicios'>
                                    <RendimientoServiciosModule />
                                </Route>
                                <Route path='/preparacion-material'>
                                    <PreparacionesMaterial />
                                </Route>
                                <Route path='/vehiculos'>
                                    <VehiculosModule />
                                </Route>
                                <Route path='/proveedores'>
                                    <ProveedoresModule />
                                </Route>
                                <Route path='/albaranes-compra'>
                                    <AlbaranesCompraModule />
                                </Route>
                                <Route path='/revision-material'>
                                    <RevisionMaterial />
                                </Route>
                                <Route path='/documentos'>
                                    <DocumentosModule />
                                </Route>
                                <Route path='/control-jornada'>
                                    <ControlJornada />
                                </Route>
                                <Route path='/revisar-partes-trabajo'>
                                    <RevisionPartes />
                                </Route>
                                <Route path='/incidencias'>
                                    <RevisionIncidencias />
                                </Route>
                                <Route
                                    path='/mi-cuenta'
                                    render={({ match }) => (
                                        <ProfileForm basePath='/' match={match} onError={(err) => console.error(err)} />
                                    )}
                                />
                                <Route path='/clientes'>
                                    <ClientesModule />
                                </Route>
                            </>
                        )}
                    </Switch>
                </div>
            </MainAppContext.Provider>
        </div>
    );
}
