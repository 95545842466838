import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useField } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { companiesProvider } from '../../../api';
import useAuthState from '../../../AuthState';
import { useFetchData } from '../../../hooks/useFetchData';
import CategoriaOperarioField from '../../common/fields/CategoriaOperarioField';
import { useHasChanged } from '../../common/fields/DateRangePickerNew';
import TipoHoraField from '../../common/fields/TipoHoraField';
import DialogForm from '../../common/forms/DialogForm';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 650,
        },
        searchIcon: {
            fontSize: 20,
            color: theme.palette.neutral.primary,
        },
        fields: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            flex: 1,
        },
    }),
    { name: 'ManoObraEditorContent' },
);

function ManoObraEditorContent() {
    const classes = useStyles();

    const {
        userInfo: { preferencias: { usar_precios: usarPrecios } = {} },
    } = useAuthState();

    const [, { value: categoriaOperarioValue }] = useField('categoria_operario');
    const [, { value: tipoHoraValue }] = useField('tipo_hora');
    const [, , { setValue: setPrecioCosteValue }] = useField('precio_unitario_coste');
    const [, , { setValue: setPrecioVentaValue }] = useField('precio_unitario');

    const preciosFetchDataFn = useCallback(() => companiesProvider.getOperarioPrecios(), []);
    const { data: precios, fetchData: fetchPrecios } = useFetchData(preciosFetchDataFn);

    useEffect(() => {
        fetchPrecios();
    }, [fetchPrecios]);

    const groupedPreciosByCategoriaAndTipoHora = useMemo(
        () =>
            precios?.reduce((acc, precio) => {
                if (!acc[precio.categoria_operario_id]) {
                    acc[precio.categoria_operario_id] = {};
                }
                acc[precio.categoria_operario_id][precio.tipo_hora_id] = precio;
                return acc;
            }, {}),
        [precios],
    );

    const categoriaOperarioId = categoriaOperarioValue?.id;
    const tipoHoraId = tipoHoraValue?.id;
    const categoriaOperarioChanged = useHasChanged(categoriaOperarioId);
    const tipoHoraChanged = useHasChanged(tipoHoraId);
    const precioDisabled = Boolean(categoriaOperarioId && tipoHoraId);

    useEffect(() => {
        if (!categoriaOperarioChanged && !tipoHoraChanged) return;

        if (!categoriaOperarioId || !tipoHoraId) return;

        const precio = groupedPreciosByCategoriaAndTipoHora[categoriaOperarioId]?.[tipoHoraId];
        if (!precio) return;

        setPrecioCosteValue(precio.precio_coste);
        setPrecioVentaValue(precio.precio_venta);
    }, [
        groupedPreciosByCategoriaAndTipoHora,
        categoriaOperarioId,
        tipoHoraId,
        categoriaOperarioChanged,
        tipoHoraChanged,
    ]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className={classes.fields}>
                <TextField name='horas' label='Horas' type='number' />
                <TextField name='minutos' label='Minutos' type='number' />
            </Grid>
            {usarPrecios && (
                <Grid item xs={12} className={classes.fields}>
                    <TextField
                        name='precio_unitario_coste'
                        label='Precio coste'
                        type='number'
                        step='.01'
                        disabled={precioDisabled}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                        }}
                    />
                    <TextField
                        name='precio_unitario'
                        label='Precio venta'
                        type='number'
                        step='.01'
                        disabled={precioDisabled}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                        }}
                    />
                    <TextField
                        name='descuento'
                        label='Dto'
                        type='number'
                        step='.01'
                        disabled={precioDisabled}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                        }}
                    />
                </Grid>
            )}
            <Grid item xs={6} className={classes.fields}>
                <CategoriaOperarioField />
            </Grid>
            <Grid item xs={6} className={classes.fields}>
                <TipoHoraField />
            </Grid>
        </Grid>
    );
}

export default function ManoObraEditor({ jornada, onSave, ...props }) {
    const ManoObraSchema = useMemo(
        () =>
            Yup.object().shape({
                horas: Yup.number().required('Requerido'),
                minutos: Yup.number().required('Requerido'),
                precio_unitario_coste: Yup.number().required('Requerido'),
                precio_unitario: Yup.number().required('Requerido'),
                descuento: Yup.number().required('Requerido'),
            }),
        [],
    );

    return (
        <DialogForm
            title='Editar mano de obra'
            FormikProps={{
                initialValues: {
                    horas: jornada?.tiempo?.horas ?? '',
                    minutos: jornada?.tiempo?.minutos ?? '',
                    precio_unitario_coste: jornada?.precio_unitario_coste ?? 0,
                    precio_unitario: jornada?.precio_unitario ?? 0,
                    descuento: jornada?.descuento ?? 0,
                    categoria_operario: jornada?.categoria_operario_id ? { id: jornada.categoria_operario_id } : null,
                    tipo_hora: jornada?.tipo_hora_id ? { id: jornada.tipo_hora_id } : null,
                },
                validationSchema: ManoObraSchema,
                onSubmit: (
                    { categoria_operario: categoria, tipo_hora: tipoHora, horas, minutos, ...values },
                    { setSubmitting, setFieldError },
                ) => {
                    onSave({
                        ...values,
                        tiempo: { horas, minutos },
                        categoria_operario_id: categoria ? categoria.id : null,
                        tipo_hora_id: tipoHora ? tipoHora.id : null,
                    }).catch((err) => {
                        if (err.status === 400) {
                            for (const [field, errors] of Object.entries(err.message)) {
                                setFieldError(field, errors.join('\n'));
                            }
                        }
                        setSubmitting(false);
                    });
                },
            }}
            {...props}
        >
            {() => <ManoObraEditorContent open={props.open} />}
        </DialogForm>
    );
}

ManoObraEditor.propTypes = {
    jornada: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
