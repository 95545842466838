import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Children } from 'react';
import { tipoPresupuestoLineaLabels } from '../../../api/presupuestos';
import { TableCell, TableRow } from '../PresupuestoView/Table';
import { tipoLineaIcons } from './Icons';

const useStyles = makeStyles(
    (theme) => ({
        hidden: {
            display: 'none',
        },
        number: {
            textAlign: 'right',
        },
        unidades: {
            paddingRight: 4,
        },
        tipoUnidades: {
            paddingLeft: 4,
        },
        descripcion: {
            whiteSpace: 'pre-line',
        },
        actions: {
            whiteSpace: 'nowrap',
            textAlign: 'right',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        toggleButton: {
            marginRight: theme.spacing(0.5),
        },
        conceptoCell: {
            '& svg': {
                fontSize: 20,
                color: theme.palette.neutral.grey4,
                marginBottom: -6,
                marginRight: 4,
            },
        },
        capitulo: {
            background: theme.palette.primary.backgroundTint,
        },
    }),
    { name: 'PresupuestoTreeViewLinea' },
);

export default function PresupuestoTreeViewLinea({
    linea,
    orden: ordenParent = [],
    expandedLineas,
    onToggle,
    onSave,
    renderCells,
    lineasFilterFn,
    isExpandable,
    hidden = false,
    hideDescripcion = false,
}) {
    const classes = useStyles();
    const orden = [...ordenParent, linea.orden];
    const ToggleIcon = expandedLineas.includes(linea.id) ? IndeterminateCheckBoxOutlinedIcon : AddBoxOutlinedIcon;
    const expandable = isExpandable(linea);

    const Icon = tipoLineaIcons[linea.tipo_linea];

    const cells = renderCells({ classes, linea });

    return (
        <>
            <TableRow
                className={clsx(hidden ? classes.hidden : null, linea.parent_id === null ? classes.capitulo : null)}
            >
                <TableCell
                    style={{
                        paddingLeft:
                            8 + ordenParent.length * 12 + (expandable ? 0 : 24) + (linea.lineas.length === 0 ? 36 : 0),
                    }}
                >
                    {expandable && linea.lineas.length > 0 && (
                        <IconButton size='small' onClick={() => onToggle(linea.id)} className={classes.toggleButton}>
                            <ToggleIcon fontSize='small' />
                        </IconButton>
                    )}
                    {orden.join('.')}
                </TableCell>
                <TableCell>{linea.referencia}</TableCell>
                <TableCell className={classes.conceptoCell}>
                    {Icon && (
                        <Tooltip arrow title={tipoPresupuestoLineaLabels[linea.tipo_linea]}>
                            <Icon />
                        </Tooltip>
                    )}{' '}
                    {linea.concepto}
                </TableCell>
                {Children.toArray(cells)}
            </TableRow>
            {linea.descripcion && (
                <TableRow className={hidden || hideDescripcion ? classes.hidden : null}>
                    <TableCell colSpan={2} />
                    <TableCell className={classes.descripcion}>{linea.descripcion}</TableCell>
                    {cells.length > 1 && <TableCell colSpan={cells.length} />}
                </TableRow>
            )}
            {linea.lineas.filter(lineasFilterFn ?? (() => true)).map((childLineas, index) => (
                <PresupuestoTreeViewLinea
                    key={index}
                    linea={childLineas}
                    orden={orden}
                    expandedLineas={expandedLineas}
                    onToggle={onToggle}
                    onSave={onSave}
                    hidden={hidden || !expandedLineas.includes(linea.id)}
                    hideDescripcion={hideDescripcion}
                    renderCells={renderCells}
                    lineasFilterFn={lineasFilterFn}
                    isExpandable={isExpandable}
                />
            ))}
        </>
    );
}

PresupuestoTreeViewLinea.propTypes = {
    linea: PropTypes.any,
    orden: PropTypes.any,
    expandedLineas: PropTypes.array,
    onToggle: PropTypes.func,
    onSave: PropTypes.func,
    hidden: PropTypes.bool,
    hideDescripcion: PropTypes.bool,
    lineasFilterFn: PropTypes.func,
    renderCells: PropTypes.func,
    isExpandable: PropTypes.func,
};
