import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { estadoLabels } from '../../api/tareas';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderRadius: 4,
            color: 'white',
            padding: theme.spacing(0, 1),
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            height: 24,
            '&.SIN_PLANIFICAR': {
                backgroundColor: '#A3ACBA',
            },
            '&.PENDIENTE': {
                backgroundColor: '#ECBB02',
            },
            '&.EN_PROGRESO': {
                backgroundColor: '#FDA729',
            },
            '&.FINALIZADA': {
                backgroundColor: '#1BC47D',
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    }),
    { name: 'ServicioEstadoChip' },
);

export default function ServicioEstadoChip({ value, style, label, className, ...props }) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, value, className)} style={style} {...props}>
            <Typography variant='subtitle2'>{label ?? estadoLabels[value]}</Typography>
        </div>
    );
}

ServicioEstadoChip.propTypes = {
    value: PropTypes.string.isRequired,
    style: PropTypes.object,
    label: PropTypes.string,
    className: PropTypes.string,
};
