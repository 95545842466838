import Alert from '@material-ui/lab/Alert';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import EditFormHeader from '../../common/forms/EditForm/EditFormHeader';
import { FormTab, FormTabs } from '../../common/forms/EditForm/FormTabs';
import AjustesTab from './Ajustes/AjustesTab';
import DatosFacturacionTab from './DatosFacturacionTab';
import {
    AJUSTES_ROUTE,
    DATOS_FACTURACION_ROUTE,
    EVENTOS_ROUTE,
    SUSCRIPCION_ROUTE,
    USUARIOS_ROUTE,
} from './EditProfileForm';
import EventosTab from './EventosTab';
import SuscripcionTab from './SuscripcionTab';
import UsuariosTab from './UsuariosTab';

export function EditProfileFormContent() {
    const { data } = useEditFormData();

    const lineaDireccionSecundaria = [data.codigo_postal, data.poblacion, data.provincia].filter(Boolean).join(', ');

    const facturacionAllData = data.name && data.direccion && lineaDireccionSecundaria && data.nif && data.telefono;
    const datosPagoAllData = data.datos_pago?.nombre && data.datos_pago?.numero_cuenta;
    const hasAllData = facturacionAllData && datosPagoAllData;

    return (
        <>
            <EditFormHeader label='Mi cuenta' name={data.name}></EditFormHeader>

            <FormTabs
                beforeContentComponent={
                    !hasAllData && (
                        <Alert variant='filled' severity='warning' style={{ marginBottom: 24 }}>
                            Para activar tu suscripción, necesitas rellenar los datos de facturación.
                        </Alert>
                    )
                }
            >
                <FormTab addWrapper path={DATOS_FACTURACION_ROUTE} label='Datos de facturación'>
                    <DatosFacturacionTab />
                </FormTab>
                <FormTab addWrapper path={USUARIOS_ROUTE} label='Usuarios y contraseñas'>
                    <UsuariosTab />
                </FormTab>
                <FormTab addWrapper path={SUSCRIPCION_ROUTE} label='Suscripción'>
                    <SuscripcionTab />
                </FormTab>
                <FormTab addWrapper path={EVENTOS_ROUTE} label='Registro de eventos'>
                    <EventosTab />
                </FormTab>
                <FormTab path={AJUSTES_ROUTE} label='Ajustes'>
                    <AjustesTab />
                </FormTab>
            </FormTabs>
        </>
    );
}
