import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { tareasProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import { SwitchWithLabel } from '../common/fields/Switch';
import { ListaServicios } from '../servicios/ListaServicios/ListaServicios';
import useDefaultServiciosColumns from '../servicios/useDefaultServiciosColumns';

export default forwardRef(function AllTareasList(props, ref) {
    const fetchDataFn = useCallback(() => tareasProvider.getTodas(), []);
    const { data, fetchData, setData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, []);

    const columns = useDefaultServiciosColumns();

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            tableOptions: {
                fillEmptySpace: false,
                forceHiddenColumns: ['operarios-visible'],
                extraFilters: (setFilter, tableColumns) => {
                    const column = tableColumns.find((c) => c.id === 'operarios-visible');
                    const showingAll = column.filterValue === undefined;

                    return (
                        <SwitchWithLabel
                            label='Mostrar solo los servicios que no ve ningún operario'
                            field={{
                                name: 'solo-servicios-no-mostrados',
                                onChange: () => column.setFilter(showingAll ? true : undefined),
                                value: !showingAll,
                            }}
                        />
                    );
                },
            },
        }),
        [],
    );

    return (
        <ListaServicios
            tableId='tareasAll'
            tareas={data}
            setTareas={setData}
            columns={columns}
            options={options}
            style={{
                marginBottom: 24,
            }}
            basePath='/tareas'
            tableTitle='Lista de servicios'
        />
    );
});
